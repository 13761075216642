import { apiUrl, client } from '../axios';
import { AuthSDK } from '../utils/auth_provider';
import { parseWithZod } from '../zod';
import { z } from 'zod';
import { UserCompanyWithCompanyDetailsSchema } from './users_companies';
const UserStatusValues = [
    'staged',
    'active',
    'deactivated'
];
export const UserStatusEnum = z.enum(UserStatusValues);
export const UserJobSchema = z.object({
    user_uuid: z.string().uuid(),
    job_uuid: z.string().uuid(),
    company_role_uuid: z.string().uuid()
});
export const UserPublicProfileSchema = z.object({
    uuid: z.string().uuid(),
    first_name: z.string().optional().nullable(),
    last_name: z.string().optional().nullable(),
    email: z.string().email(),
    status: UserStatusEnum
});
export const UserSchema = UserPublicProfileSchema.merge(z.object({
    user_companies: z.array(UserCompanyWithCompanyDetailsSchema),
    user_jobs: z.array(UserJobSchema),
    accepted_terms_and_conditions_id: z.number(),
    is_superadmin: z.boolean().optional()
}));
export const UserJobWithUserPublicProfileSchema = z.object({
    user_uuid: z.string().uuid(),
    job_uuid: z.string().uuid(),
    company_role_uuid: z.string().uuid(),
    user: UserPublicProfileSchema
});
export const hasPermission = ({ user, company, permissions, job, permissionPath }) => {
    if (!user || !company || !permissions) {
        return false;
    }
    if (user.is_superadmin) {
        return true;
    }
    const hydratedPermissionUUIDs = permissions
        .filter(({ path }) => path.startsWith(permissionPath.replace('*', '')))
        .map(x => x.uuid);
    if (hydratedPermissionUUIDs.length === 0) {
        return false;
    }
    // Does the user have an association with the company?
    const userCompany = user?.user_companies?.find(userCompany => userCompany.company_uuid === company?.public_info.uuid);
    if (userCompany) {
        const companyRole = company.company_roles?.find(role => role.uuid === userCompany.company_role_uuid);
        if (!companyRole) {
            return false;
        }
        return companyRole.company_role_permissions.some(x => hydratedPermissionUUIDs.includes(x.permission_uuid));
    }
    const userJob = user?.user_jobs.find(userJob => userJob.job_uuid === job?.uuid);
    if (userJob) {
        const companyRole = company.company_roles.find(role => role.uuid === userJob.company_role_uuid);
        if (!companyRole) {
            return false;
        }
        return companyRole.company_role_permissions.some(x => hydratedPermissionUUIDs.includes(x.permission_uuid));
    }
    return false;
};
export const getUserPermissionsForCompany = (user, company, permissions) => {
    if (!user || !company) {
        return [];
    }
    if (user.is_superadmin) {
        return permissions.map(({ path }) => path);
    }
    const userCompany = user?.user_companies.find(userCompany => userCompany.company_uuid === company?.public_info.uuid);
    if (!userCompany) {
        return [];
    }
    const companyRole = company.company_roles.find(role => role.uuid === userCompany.company_role_uuid);
    if (!companyRole) {
        return [];
    }
    return companyRole.company_role_permissions.map((x) => permissions.find(({ uuid }) => uuid === x.permission_uuid)?.path).filter(Boolean);
};
export const getUser = async () => {
    try {
        const result = await client.get(`${apiUrl}users/me`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return parseWithZod(UserSchema, result.data);
    }
    catch (e) {
        console.error('Error getting user', e);
        return undefined;
    }
};
export const updateUser = async (user) => {
    try {
        const result = await client.patch(`${apiUrl}users/me`, user, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return result.data;
    }
    catch (e) {
        console.error('Error updating user', e);
        return undefined;
    }
};
