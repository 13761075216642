import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { validateEmail } from '../../../code/validators';
import { Button } from '../../../components/buttons/button';
import { Section } from '../../../components/containers/section';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { Input } from '../../../components/inputs_and_selections/input';
import { UserX } from 'lucide-react';
import { addUserToJob, removeUserFromJob } from '../../../code/models/lead';
import { TableLite } from '../../../components/content_display/table_lite';
import { UserStatusEnum } from '../../../code/models/user';
import { AdminContext } from '../admin_layout';
export const Invitations = ({ title, helpText, company, lead, setLead }) => {
    const adminContext = useContext(AdminContext);
    const [invitationEmail, setInvitationEmail] = useState('');
    const sendInvitation = async () => {
        try {
            await sendInvitationInner(invitationEmail);
            setInvitationEmail('');
        }
        catch (e) {
            console.error('Error sending invitation', e);
        }
    };
    const revokeInvitation = async (userUuid) => {
        setLead({
            ...lead,
            job_users: lead.job_users?.filter(x => x.user_uuid !== userUuid)
        });
        await removeUserFromJob({
            companyUuid: lead.company_uuid,
            jobUuid: lead.uuid,
            jobUserUuid: userUuid
        });
    };
    const sendInvitationInner = async (email) => {
        if (!email)
            return;
        // Hardcoded for the moment
        const surveyorRole = company?.company_roles.find(x => x.name === 'Surveyor');
        if (!surveyorRole) {
            console.error('Surveyor role not found');
            return;
        }
        const userCompany = await addUserToJob({
            companyUuid: lead.company_uuid,
            jobUuid: lead.uuid,
            roleUuid: surveyorRole.uuid,
            email
        });
        if (userCompany) {
            setLead({
                ...lead,
                job_users: [...lead.job_users || [], {
                        user_uuid: userCompany.user_uuid,
                        job_uuid: lead.uuid,
                        company_role_uuid: userCompany.company_role_uuid,
                        user: {
                            uuid: userCompany.user_uuid,
                            email,
                            first_name: '',
                            last_name: '',
                            status: UserStatusEnum.enum.staged
                        }
                    }]
            });
            adminContext.setCompanyJobUsers([...(adminContext.data.companyJobUsers || []), userCompany]);
        }
    };
    return (_jsx(Section, { border: true, title: title, children: _jsxs("div", { className: "flex flex-col gap-4", children: [helpText && _jsx("p", { className: "text-sm text-gray-500", children: helpText }), _jsxs("div", { className: 'flex flex-col sm:flex-row gap-4 sm:items-start sm:justify-between', children: [_jsx(Input, { className: 'flex-1', value: invitationEmail, setValue: setInvitationEmail, placeholder: 'Email address', validator: validateEmail, doNotValidateWhenEmpty: true }), _jsx(Button, { onClick: sendInvitation, disabled: !validateEmail(invitationEmail).value, children: "Send invitation" })] }), (lead.job_users && lead.job_users.length > 0) &&
                    _jsx(TableLite, { columns: [
                            { name: 'Email', render: (row) => _jsx(_Fragment, { children: row.user.email }) },
                            { name: 'Status', render: (row) => _jsx(Badge, { color: row.user.status === 'active' ? 'GREEN' : 'YELLOW', text: row.user.status === 'active' ? 'Accepted' : 'Pending' }) },
                            { name: 'Actions', render: (row) => _jsx(Button, { size: "SM", colour: "LIGHT", className: "ml-auto", onClick: () => revokeInvitation(row.user_uuid), iconLeft: UserX, children: "Revoke" }) }
                        ], rows: lead.job_users })] }) }));
};
