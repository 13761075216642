import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import TabbedDashboardLayout from '../../../layouts/tabbed_dashboard_layout';
import { EnquiryManagerDashboard } from './enquiry_manager/tabs/enquiry_manager_dashboard';
import { AdminContext } from '../admin_layout';
import { getCompany } from '../../../code/models/company';
import { getAddressIncludingPostcode, getImages, getLead, getSurvey, patchLead } from '../../../code/models/lead';
import { getCalculatedEstimates } from '../../../code/models/calculated_estimate';
import { ensureLeadHasEPCRecommendations, ensureLeadHasLocation3D, ensurePropertySurveyBackwardsCompatibility, isPreFlowFeatureSurvey } from '../../../code/models/property';
import { calculateEstimate } from '../../../code/calculate_estimate';
import { CostEstimateEditor } from './enquiry_manager/tabs/cost_estimate_editor';
import { EnquiryData } from './enquiry_manager/tabs/enquiry_data';
import { EstimatePreview } from './enquiry_manager/tabs/estimate_preview';
import { EnquiryHistory } from './enquiry_manager/tabs/enquiry_history';
import { useDebounceCallback } from 'usehooks-ts';
import { SiteDetails } from '../../heat_loss/survey/site_details';
import { convertLatLngListToLatLongLiteral } from '../../../code/geocoding';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../heat_loss/db';
import { DEFAULT_PROPERTY_SURVEY, DEFAULT_SURVEY_DESIGN } from '../../../code/survey_defaults';
import { getCustomMaterials } from '../../../code/models/material';
import { Invitations } from '../components/invitations';
import { chain, compact, sum } from 'lodash';
import { FloorList } from '../../heat_loss/floor/floor_list';
import { DefaultMaterialsTab } from '../../heat_loss/materials/default_materials_tab';
import { CylinderLocation } from '../../heat_loss/survey/cylinder_location';
import { Electrics } from '../../heat_loss/survey/electrics';
import { ExistingHeatingSystem } from '../../heat_loss/survey/existing_heating_system';
import { HeatPumpLocation } from '../../heat_loss/survey/heat_pump_location';
import { Pipework } from '../../heat_loss/survey/pipework';
import { NewFloor, Survey } from '../../heat_loss/survey/survey';
import { SurveySettings } from '../../heat_loss/survey/survey_settings';
import { DesignHome } from '../../heat_loss/design_home';
import { getFloorAreaM2, getHeatTransferCoefficientWattsPerKelvin, getRoomTemp, getRoomWatts, getTotalHeatLossWatts } from '../../../code/models/heat_loss';
import { getCylinderReheatCalculationRow, getDailyHotWaterVolumeL, getHotWaterCalculationRowLegionella, getHotWaterCalculationRowNormalOperation, getHotWaterCylinders, getNumberOfOccupants } from '../../../code/models/hot_water_cylinder';
import { eligibleForHeatPumpPlus, getPerformanceEstimateSummary } from '../../../code/models/performance_estimate';
import { getPipeData } from '../../../code/models/pipes';
import { getEmitterSizeName, getEmitterTypeName, getFullEmittersListByStatus } from '../../../code/models/radiator';
import { getEmitterWatts, RADIATOR_MODELS } from '../../../code/models/radiator_model';
import { findMaxValidFlowTempForScop, findMinValidFlowTempForScop, getHeatPumpCapacityAtOutsideTempAndFlowTemp, getHeatPumpScopAtFlowTemp } from '../../../code/models/range_heat_pump';
import { getRoomEmitterWatts } from '../../../code/models/room';
import { getSoundAssessmentData } from '../../../code/sound_assessment';
import { CylinderDesignPage } from '../../heat_loss/design/pages/cylinder_design_page';
import { EmitterDesignPage, getRadiatorBadge } from '../../heat_loss/design/pages/emitter_design_page';
import { HeatPumpDesign } from '../../heat_loss/design/pages/heat_pump_design';
import { PerformanceDesignPage } from '../../heat_loss/design/pages/performance_design_page';
import { PipeworkDesignPage } from '../../heat_loss/design/pages/pipework_design_page';
import { getDesignConditions } from '../../../code/models/design_temp';
import { FloorPage } from '../../heat_loss/floor/floor';
import { Error404Page } from '../../error_pages';
import { HeatLossReportSettings } from '../../heat_loss/proposal/heatloss_report_settings';
import { FLOW_CANCELLED_VALUE, FLOW_FINISHED_VALUE } from '../../heat_loss/survey/components/survey_section_flow_wrapper';
import { getUnderfloorHeatingOutput } from '../../../code/models/underfloor_heating';
import { ProposalConfigurator } from './proposal_configurator/proposal_configurator';
import { JobHistory } from './history/job_history';
import { hasPermission } from '../../../code/models/user';
import { captureException } from '@sentry/react';
import { Loader } from '../../../components/indicators_and_messaging/loader';
import { syncTick } from '../sync';
import { getHeatPumps } from '../../../code/models/heat_pump';
import { JobNotFound } from '../job_not_found';
import { getAllSurveyImages } from './get_all_survey_images';
import { UnauthorizedPage } from '../unauthorized_page';
import { PageHeader } from '../../heat_loss/design/components/design_page_header';
export const IsOfflineContext = React.createContext(false);
export const InstallerAdminPage = ({ leadUUID, tab, secondaryTab, companyPublicInfo, currentPath, navigateTo, basePath, stages, isSyncing, setIsSyncing, setHasSynced, permissions }) => {
    const adminContext = useContext(AdminContext);
    const { isOffline } = adminContext;
    const [leadSaved, setLeadSaved] = useState(false);
    const [loading, setLoading] = useState(false);
    const [estimates, setEstimates] = useState([]);
    const [currentRoomId, setCurrentRoomId] = useState();
    const [stageSize, setStageSize] = useState({
        width: 0,
        height: 0
    });
    const [stageScale, setStageScale] = useState(0.05);
    const [stagePosition, setStagePosition] = useState({ x: stageSize.width / 2, y: stageSize.height / 2 });
    const debouncePatchLead = useDebounceCallback(async (lead, user) => {
        try {
            if (!lead.uuid || !user || !lead?.company_uuid)
                return;
            await patchLead({ uuid: lead.uuid, lead, companyUUID: lead?.company_uuid });
            setLeadSaved(true);
        }
        catch (e) {
            console.error(e);
        }
    }, 1000);
    const lead = useLiveQuery(() => db.jobs.get(leadUUID));
    const company = useLiveQuery(() => lead && db.companies.get(lead.company_uuid), [lead]);
    const companyRoles = useLiveQuery(() => db.company_roles.toArray(), []) ?? [];
    const companyRolePermissions = useLiveQuery(() => db.company_role_permissions.toArray());
    const dbHeatPumps = useLiveQuery(() => db.heat_pumps.where('company_uuid').equals(lead?.company_uuid ?? '').toArray(), [lead]) ?? [];
    const heatPumps = dbHeatPumps.filter(x => !x.deleted_at);
    const dbHotWaterCylinders = useLiveQuery(() => db.hot_water_cylinders.where('company_uuid').equals(lead?.company_uuid ?? '').toArray(), [lead]) ?? [];
    const hotWaterCylinders = dbHotWaterCylinders.filter(x => !x.deleted_at);
    const hydratedCompanyRoles = companyRoles.map(x => ({
        ...x,
        company_role_permissions: companyRolePermissions?.filter(y => y.company_role_uuid === x.uuid)
    }));
    const hydratedCompany = { ...company, company_roles: hydratedCompanyRoles.filter(x => x.company_uuid === company?.uuid) };
    const EXPIRE_30_DAYS = 30 * 24 * 60 * 60 * 1000;
    const setLead = useCallback((lead) => {
        setLeadSaved(false);
        db.jobs.update(lead.uuid, lead);
        debouncePatchLead(lead, adminContext.data.user);
    }, [adminContext.data.user]);
    const loadData = async () => {
        console.log('Loading data...');
        setLoading(true);
        try {
            const lead = await ensureLeadHasEPCRecommendations((await getLead(leadUUID)), companyPublicInfo?.uuid, true);
            if (!lead) {
                return;
            }
            await db.jobs.put(lead);
            const EXPIRE_24_HOURS = (24 * 60 * 60 * 1000);
            const dbCompany = await db.companies.get(lead.company_uuid);
            if (!dbCompany?.expires_at || new Date().getTime() > dbCompany.expires_at) {
                console.log('Requesting company..');
                const apiCompany = await getCompany(lead.company_uuid, lead.uuid);
                apiCompany && await db.companies.put({ ...apiCompany, expires_at: new Date().getTime() + EXPIRE_24_HOURS });
            }
            // We need to tick first so we ensure any sync before re-requesting.
            const hasError = await syncTick(isSyncing, setIsSyncing, setHasSynced, adminContext.setIsOffline);
            if (hasError)
                return;
            // We must get custom_materials, heat_pumps and cylinders per job because each job may belong to a different company compared to the company you are currently logged in as. (Being shared a job for example.)
            const dbCustomMaterials = await db.custom_materials.where('company_uuid').equals(dbCompany?.uuid ?? '').toArray();
            if (!dbCustomMaterials || dbCustomMaterials.length === 0 || dbCustomMaterials.some(x => !x.expires_at || new Date().getTime() > x.expires_at)) {
                console.log('Requesting custom materials...');
                const customMaterials = await getCustomMaterials(lead.uuid, lead.company_uuid);
                if (customMaterials && customMaterials?.length > 0) {
                    await db.custom_materials.bulkPut(customMaterials.map(x => ({ ...x, expires_at: new Date().getTime() + EXPIRE_24_HOURS })));
                }
            }
            const heatPumps = await getHeatPumps(lead.company_uuid, lead.uuid);
            if (heatPumps && heatPumps?.length > 0) {
                await db.heat_pumps.bulkPut(heatPumps.map(x => ({ ...x, company_uuid: lead.company_uuid, expires_at: new Date().getTime() + EXPIRE_24_HOURS })));
            }
            const hotWaterCylinders = await getHotWaterCylinders(lead.company_uuid, lead.uuid);
            if (hotWaterCylinders && hotWaterCylinders?.length > 0) {
                await db.hot_water_cylinders.bulkPut(hotWaterCylinders.map(x => ({ ...x, company_uuid: lead.company_uuid, expires_at: new Date().getTime() + EXPIRE_24_HOURS })));
            }
            const survey = await getSurvey(lead.uuid, lead.company_uuid);
            if (survey) {
                await db.surveys.put({ ...survey, expires_at: new Date().getTime() + EXPIRE_30_DAYS }, survey.uuid);
                const surveyImages = getAllSurveyImages(survey.data);
                if (surveyImages.length > 0) {
                    const apiSurveyImages = await getImages(surveyImages, survey.uuid, lead.company_uuid);
                    await db.files.bulkPut(apiSurveyImages);
                }
            }
            const newLead = await ensureLeadHasLocation3D(lead, companyPublicInfo?.uuid);
            setLead(newLead);
            // Getting estimates may fail if user doesn't have permission to view them
            try {
                const estimates = await getCalculatedEstimates(leadUUID, companyPublicInfo?.uuid) ?? [];
                setEstimates(estimates);
            }
            catch (e) {
                // Fail silently
            }
        }
        catch (e) {
            console.error(e);
            captureException(e);
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        loadData();
    }, []);
    useEffect(() => {
        // We need to do a raw regex check here, react router useMatch does not work.
        const surveyPage = /\/admin\/quotes\/[0-9a-fA-F-]{36}\/survey\/floors\/(.*)$/;
        const isSidebarVisible = !surveyPage.test(window.location.pathname);
        adminContext.setIsSidebarVisible(isSidebarVisible);
    }, [currentPath]);
    const surveyDB = useLiveQuery(async () => await db.surveys.get(leadUUID));
    const customMaterials = useLiveQuery(() => db.custom_materials.toArray()) ?? [];
    const files = useLiveQuery(() => db.files.toArray()) ?? [];
    const stageRef = useRef(null);
    if (loading) {
        return _jsx("div", { className: 'p-5 flex justify-center', children: _jsx(Loader, {}) });
    }
    if (!lead) {
        return _jsx(JobNotFound, { isOffline: isOffline, navigateTo: navigateTo });
    }
    const estimate = calculateEstimate(lead, heatPumps ?? [], hotWaterCylinders ?? [], hydratedCompany);
    const survey = surveyDB ? ensurePropertySurveyBackwardsCompatibility(surveyDB.data, adminContext.data.genericMaterials) : DEFAULT_PROPERTY_SURVEY;
    const setSurvey = async (updateFn) => {
        const surveyOrNew = await db.surveys.get(leadUUID);
        const updatedSurvey = typeof updateFn === 'function' ? updateFn(surveyOrNew.data) : updateFn;
        await db.surveys.update(leadUUID, { data: updatedSurvey, updated_at: new Date().getTime(), is_modified: true, expires_at: new Date().getTime() + EXPIRE_30_DAYS });
    };
    const setFiles = async (updateFn) => {
        const files = await db.files.filter(x => x.company_uuid === companyPublicInfo?.uuid).toArray();
        const updatedFiles = typeof updateFn === 'function' ? updateFn(files) : updateFn;
        await db.files.bulkPut(updatedFiles.map(x => ({ key: x.uuid, ...x, expires_at: new Date().getTime() + EXPIRE_30_DAYS })));
    };
    const setCustomMaterials = async (updateFn) => {
        const customMaterials = await db.custom_materials.filter(x => x.company_uuid === companyPublicInfo?.uuid && !x.deleted_at).toArray();
        const updatedMaterials = typeof updateFn === 'function' ? updateFn(customMaterials) : updateFn;
        await db.custom_materials.bulkPut(updatedMaterials.map(x => ({ key: x.uuid, ...x, expires_at: new Date().getTime() + EXPIRE_30_DAYS })));
    };
    const allSynced = (!surveyDB || !surveyDB.is_modified) &&
        files.every(x => !x.is_modified) &&
        customMaterials.every(x => !x.is_modified);
    const setLocationImages = async (value) => await setSurvey({ ...survey, location_images: value });
    const setCustomRadiatorModels = (updateFn) => {
        setSurvey((prevSurvey) => ({
            ...prevSurvey,
            custom_radiator_models: updateFn(prevSurvey.custom_radiator_models)
        }));
    };
    const customRadiatorModels = survey.custom_radiator_models; // Doing this at top level so can easily swap out for a database read when we move custom radiator models to the company level
    const allRadiatorModels = [...RADIATOR_MODELS, ...customRadiatorModels];
    const setFloors = async (floors) => await setSurvey(prev => ({ ...prev, floors }));
    const setFloor = (floor) => {
        setFloors([...survey.floors.map(x => x.uuid === floor.uuid ? floor : x)]);
    };
    const removeFloor = (id) => {
        const filteredFloors = survey.floors.filter(x => x.uuid !== id);
        setFloors([...filteredFloors]);
        navigateToWithBasePath('/survey/floors');
    };
    const setDesign = async (design) => await setSurvey(prev => ({ ...prev, designs: [design] }));
    const setFlowTemp = async (temp) => await setDesign({ ...design, flow_temp: temp });
    const design = survey.designs[0] ?? DEFAULT_SURVEY_DESIGN;
    const currentHeatPump = heatPumps.find(x => x.uuid === design.current_heat_pump_uuid);
    const minFlowTemp = findMinValidFlowTempForScop(currentHeatPump?.range_heat_pump);
    const maxFlowTemp = findMaxValidFlowTempForScop(currentHeatPump?.range_heat_pump);
    const latLng = convertLatLngListToLatLongLiteral(lead.property.postcodeLocation);
    const altitudetoUseM = survey.altitude_override_m ?? lead.property.altitudeM;
    const { designTempDefaultC, degreeDays, groundTempC } = getDesignConditions(latLng, altitudetoUseM, survey.exposed_location); // done separately as needed in survey_settings
    const designTempC = survey.design_temp_override_c ?? designTempDefaultC;
    const currentPathWithoutBase = currentPath.replace(basePath, '');
    const navigateToWithBasePath = (url) => navigateTo(`${basePath}${url}`);
    // Do not move this page into the router, we do a lot of calculations on re-render below not needed for floorplan and slows down drag move.
    // TODO: Try to match calculations with individual pages, re-calculating logic for multiple pages where needed so we can bring this into the router.
    const singleFloorPath = /^\/survey\/floors\/([a-zA-Z0-9-]+)$/;
    if (singleFloorPath.test(currentPathWithoutBase)) {
        const uuid = currentPathWithoutBase.match(singleFloorPath)?.[1];
        const currentFloor = survey.floors.find(x => x.uuid === uuid);
        if (!currentFloor)
            return _jsx(Error404Page, {});
        return _jsx(FloorPage, { removeFloor: removeFloor, survey: survey, floors: survey.floors, floor: currentFloor, setFloor: setFloor, files: files, setFiles: setFiles, designTempC: designTempC, groundTempC: groundTempC, setSurvey: setSurvey, materials: [...adminContext.data.genericMaterials, ...customMaterials], setMaterials: setCustomMaterials, materialsLayers: adminContext.data.materialsGenericLayers, customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, allRadiatorModels: allRadiatorModels, design: design, currentRoomId: currentRoomId, setCurrentRoomId: setCurrentRoomId, stageScale: stageScale, setStageScale: setStageScale, stagePosition: stagePosition, setStagePosition: setStagePosition, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, stageRef: stageRef, stageSize: stageSize, setStageSize: setStageSize, companyUuid: lead.company_uuid, allSynced: allSynced, isOffline: isOffline, navigateTo: navigateToWithBasePath });
    }
    // Calculations
    // Inputs
    const currentHotWaterCylinder = hotWaterCylinders.find(x => x.uuid === design.current_hot_water_cylinder_uuid);
    // Summary calculations
    const totalHeatLossKw = getTotalHeatLossWatts(survey, designTempC, groundTempC) / 1000;
    // calculate demandMet by room groups, not independent rooms.
    // because some rooms are gaining heat from the other rooms in the group
    const roomGroupsHeatDemandMet = chain(survey.floors.flatMap(x => x.rooms.map(y => ({ ...y, floor: x }))))
        .groupBy(x => x.room_group_uuid ? x.room_group_uuid : x.uuid)
        .map((values, key) => {
        const roomGroupRadiatorWatts = sum(values.map(r => getRoomEmitterWatts(r, design, survey, designTempC, groundTempC)));
        const roomGroupRoomWatts = sum(values.map(r => getRoomWatts(r, r.floor.rooms, designTempC, groundTempC, survey)));
        const status = roomGroupRadiatorWatts > roomGroupRoomWatts ? 'Sufficient' : design.undersized_emitter_rooms.some(x => x.room_uuid === key) ? 'Accepted' : 'Insufficient';
        return { roomGroupKey: key, status };
    })
        .value();
    const emitterDemandStatus = roomGroupsHeatDemandMet.every(x => x.status === 'Sufficient')
        ? 'Sufficient' // sufficient if all marked sufficient
        //   Insufficient if any marked insufficient
        : roomGroupsHeatDemandMet.some(x => x.status === 'Insufficient')
            ? 'Insufficient'
            : 'Accepted'; // accepted if not all sufficient and none insufficient
    // Heat pump calculations
    const scopSpaceHeating = getHeatPumpScopAtFlowTemp(currentHeatPump?.range_heat_pump, design.flow_temp);
    const soundAssessmentData = getSoundAssessmentData(survey.sound_barrier_uuid, survey.reflective_surfaces, survey.sound_distance, currentHeatPump?.range_heat_pump?.sound_power_max_dba);
    const heatPumpCapacityResult = getHeatPumpCapacityAtOutsideTempAndFlowTemp(currentHeatPump?.range_heat_pump, designTempC, design.flow_temp);
    const soundAssessment = currentHeatPump ? soundAssessmentData?.finalResultDba ?? 0 : 0;
    // Radiator calculations
    const roomOrRoomGroups = survey.floors.flatMap(x => x.rooms.map(y => ({ uuid: y.uuid, group_uuid: y.room_group_uuid })));
    const addedOrReplacedRadiators = design.radiators
        .filter(x => roomOrRoomGroups.some(y => y.uuid === x.room_uuid || y.group_uuid === x.room_uuid))
        .map(r => ({
        radiator_type_uuid: r.emitter_type === 'RADIATOR' ? r.radiator_type?.uuid : undefined,
        name: getEmitterTypeName(r),
        dimensions: getEmitterSizeName(r),
        badge: getRadiatorBadge(r, false, design.removed_radiator_uuids, false)
    }));
    const emitterRows = [...addedOrReplacedRadiators];
    const emitterColumns = [
        { name: 'Item', render: (row) => _jsx("div", { children: row.name }) },
        { name: 'Dimensions', render: (row) => _jsx("div", { children: row.dimensions }) },
        { name: 'Badge', render: (row) => _jsx("div", { className: 'flex justify-end', children: row.badge }) }
    ];
    // Hot water calculations
    const cylinderReheatRow = getCylinderReheatCalculationRow(currentHotWaterCylinder, design.hot_water_storage_temperature_c, currentHeatPump?.range_heat_pump, designTempC);
    const numberOfOccupants = getNumberOfOccupants(survey);
    const dailyHotWaterVolumeL = getDailyHotWaterVolumeL(numberOfOccupants, survey.volume_per_person_l);
    const hotWaterRowNormal = getHotWaterCalculationRowNormalOperation(currentHeatPump?.range_heat_pump, dailyHotWaterVolumeL, design.hot_water_storage_temperature_c);
    const hotWaterRowLegionella = getHotWaterCalculationRowLegionella(design.hot_water_storage_temperature_c, currentHotWaterCylinder?.litres ?? 0, survey.legionnaires_cycle_weeks);
    const hotWaterConsumptionAnnual = hotWaterRowNormal.electricityKWhPerYear + hotWaterRowLegionella.electricityKWhPerYear;
    const scopHotWater = hotWaterRowNormal.heatingEfficiency;
    // Performance calculations
    // Ignore legionella for now in HDD based performance estimate - add back in when use a less conservative efficiency
    const designHotWaterDemandKwh = hotWaterRowNormal.heatEnergyKwhPerCycle * hotWaterRowNormal.cyclesPerYear;
    const heatLossWattsPerKelvin = getHeatTransferCoefficientWattsPerKelvin(survey, designTempC, groundTempC);
    const performanceEstimateSummary = getPerformanceEstimateSummary(survey, heatLossWattsPerKelvin, degreeDays, designHotWaterDemandKwh, scopHotWater, scopSpaceHeating, eligibleForHeatPumpPlus(currentHeatPump));
    // Use HDD based values for bill estimates for now - allow user to choose later
    const annualBillEstimateGBP = performanceEstimateSummary.hddEstimate.proposed.costPriceCap;
    const performanceEstimateColumns = [
        {
            key: 'name',
            name: 'Name',
            render: (row) => _jsxs(_Fragment, { children: [" ", row.name === 'Savings' ? _jsx("div", { className: 'font-bold', children: row.name }) : _jsx("div", { children: row.name })] })
        },
        {
            key: 'kwh',
            name: 'Energy',
            render: (row) => _jsxs(_Fragment, { children: [" ", row.name === 'Savings' ? _jsx("div", { className: 'font-bold', children: row.kwh }) : _jsx("div", { children: row.kwh })] })
        },
        {
            key: 'costUserEnteredTariff', // render with the user entered tariff so they can play. In report this won't be show, the other 3 options will be
            name: 'Bills',
            render: (row) => _jsxs(_Fragment, { children: [" ", row.name === 'Savings' ? _jsx("div", { className: 'font-bold', children: row.costUserEnteredTariff }) : _jsx("div", { children: row.costUserEnteredTariff })] })
        },
        {
            key: 'emissionsKG',
            name: 'Emissions',
            render: (row) => _jsxs(_Fragment, { children: [" ", row.name === 'Savings' ? _jsx("div", { className: 'font-bold', children: row.emissionsKG }) : _jsx("div", { children: row.emissionsKG })] })
        }
    ];
    // Pipework calculations
    const designedEmitters = getFullEmittersListByStatus(survey, design)
        .filter(x => x.status !== 'REMOVED' && x.radiator.type !== 'SECONDARY')
        .map(x => ({
        ...(x.status === 'REPLACED' && x.replacedBy ? x.replacedBy : x.radiator),
        room: x.room,
        watts: getEmitterWatts(x.status === 'REPLACED' && x.replacedBy ? x.replacedBy : x.radiator, x.room, design, survey, designTempC, groundTempC),
        coverageM2: x.radiator.emitter_type === 'UNDERFLOOR' ? getUnderfloorHeatingOutput(x.radiator, survey.manifolds, getRoomTemp(x.room, survey), getFloorAreaM2(x.room.walls), design.flow_temp, design.delta_t_flow_return_c).activeAreaM2 : undefined
    }));
    // Get the index emitter. If the survey's index emitter has been replaced then set that replacement emitter as the index emitter apart from if an override has been set in the design (this means as soon as the user changes it in design the link to the survey is lost)
    const indexEmitterReplacementUUID = designedEmitters.find(x => x.type === 'DESIGN' && x.replaces_uuid === survey.index_emitter_uuid)?.uuid;
    const indexEmitterUUID = design.index_emitter_uuid_override ?? indexEmitterReplacementUUID ?? survey.index_emitter_uuid;
    // Get the secondary emitters. If the survey's secondary index emitters have been replaced then set those replacement emitters as the secondary emitters
    const secondaryEmitterUUIDsThroughReplacement = designedEmitters.filter(x => x.type === 'DESIGN' && x.replaces_uuid && survey.secondary_index_emitter_uuids.includes(x.replaces_uuid)).map(x => x.uuid);
    const secondaryEmitterUUIDSAll = [
        ...survey.secondary_index_emitter_uuids.filter(x => !design.removed_secondary_index_emitter_uuids.includes(x)), // remaining survey emitters that were on the survey index circuit
        ...secondaryEmitterUUIDsThroughReplacement.filter(x => !design.removed_secondary_index_emitter_uuids.includes(x)), // survey emitters that were on the survey index circuit but have been replaced
        ...design.secondary_index_emitter_uuids // emitters that have been added on the design side
    ].filter(x => designedEmitters.some(y => y.uuid === x)); // only include if it's in the designed emitters list (normally selecting from design rads so not strictly needed, but clearer this way)
    const secondaryEmitterUUIDS = Array.from(new Set(secondaryEmitterUUIDSAll)); // remove duplicates - unlikely to occur but could if you added a survey to the design list of emitters and then added it on the survey side
    const pipeDataList = getPipeData(survey, design, design.delta_t_flow_return_c, design.flow_temp, heatPumpCapacityResult.capacityKw, designedEmitters, indexEmitterUUID, secondaryEmitterUUIDS);
    const settingsCompleted = survey.completed_sections.includes('SETTINGS');
    const materialsCompleted = survey.completed_sections.includes('MATERIALS');
    const useFloorplanFlow = survey.floors.length === 0 &&
        ![FLOW_FINISHED_VALUE, FLOW_CANCELLED_VALUE].includes(survey.flags_floorplan_pages_completed) && // Cancelled flow or completed flow.
        (!settingsCompleted || !materialsCompleted) &&
        !isPreFlowFeatureSurvey(survey);
    const ROUTES = [{
            path: /survey\/site_details/,
            component: () => _jsx(SiteDetails, { lead: lead, postcodeLocation: latLng, survey: survey, setSurvey: setSurvey, files: files, setFiles: setFiles, companyUUID: lead.company_uuid, isOffline: isOffline, allSynced: allSynced })
        }, {
            path: /survey\/existing_heating/,
            component: () => _jsx(ExistingHeatingSystem, { survey: survey, setSurvey: setSurvey, files: files, setFiles: setFiles, companyUUID: lead.company_uuid, isOffline: isOffline, allSynced: allSynced })
        }, {
            path: /survey\/heat_pump/,
            component: () => _jsx(HeatPumpLocation, { survey: survey, design: design, currentHeatPump: currentHeatPump, setSurvey: setSurvey, setDesign: setDesign, heatPumps: heatPumps, files: files, setFiles: setFiles, locationImages: survey.location_images, setLocationImages: setLocationImages, soundCalculation: soundAssessment, designTempC: designTempC, flowTempC: design.flow_temp, companyUUID: lead.company_uuid, isOffline: isOffline, allSynced: allSynced })
        }, {
            path: /survey\/cylinder_location/,
            component: () => _jsx(CylinderLocation, { survey: survey, setSurvey: setSurvey, files: files, setFiles: setFiles, companyUUID: lead.company_uuid, isOffline: isOffline, allSynced: allSynced })
        }, {
            path: /survey\/electrics/,
            component: () => _jsx(Electrics, { survey: survey, setSurvey: setSurvey, files: files, setFiles: setFiles, companyUUID: lead.company_uuid, isOffline: isOffline, allSynced: allSynced })
        }, {
            path: /survey\/settings/,
            component: () => _jsx(SurveySettings, { survey: survey, setSurvey: setSurvey, designTempDefault: designTempDefaultC, altitudeDefaultM: lead.property.altitudeM ?? 0, isOffline: isOffline, allSynced: allSynced })
        }, {
            path: /survey\/materials/,
            component: () => _jsx(DefaultMaterialsTab, { materials: [...adminContext.data.genericMaterials, ...customMaterials], setMaterials: setCustomMaterials, materialsLayers: adminContext.data.materialsGenericLayers, setSurvey: setSurvey, survey: survey, companyUUID: lead.company_uuid, isOffline: isOffline, allSynced: allSynced })
        }, {
            path: /survey\/floors/,
            component: () => _jsx(FloorList, { setFlowTemp: setFlowTemp, floors: survey.floors, designTempC: designTempC, groundTempC: groundTempC, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, survey: survey, design: design, setCurrentRoomId: setCurrentRoomId, setSurvey: setSurvey, navigateTo: navigateToWithBasePath, isOffline: isOffline, allSynced: allSynced, useFloorplanFlow: useFloorplanFlow, customMaterials: customMaterials, setCustomMaterials: setCustomMaterials, altitudeM: lead.property.altitudeM, companyUUID: lead.company_uuid, company: hydratedCompany })
        }, {
            path: /survey\/new_floor/,
            component: () => _jsx(NewFloor, { survey: survey, setSurvey: setSurvey, floors: survey.floors, navigateTo: navigateToWithBasePath, isOffline: isOffline, allSynced: allSynced, company: hydratedCompany })
        }, {
            path: /survey\/pipework/,
            component: () => _jsx(Pipework, { survey: survey, setSurvey: setSurvey, isOffline: isOffline, allSynced: allSynced })
        }, {
            path: /design\/heat_pump/,
            component: () => _jsx(HeatPumpDesign, { survey: survey, design: design, currentHeatPump: currentHeatPump, setSurvey: setSurvey, setDesign: setDesign, heatPumps: heatPumps, files: files, setFiles: setFiles, locationImages: survey.location_images, setLocationImages: setLocationImages, soundCalculation: soundAssessment, designTempC: designTempC, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, scop: scopSpaceHeating, totalHeatLossKW: totalHeatLossKw, companyUUID: lead.company_uuid, isOffline: isOffline, allSynced: allSynced })
        }, {
            path: /design\/emitters/,
            component: () => _jsx(EmitterDesignPage, { customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, allRadiatorModels: allRadiatorModels, floors: survey.floors, setFloor: setFloor, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, designTempC: designTempC, groundTempC: groundTempC, survey: survey, design: design, setDesign: setDesign, annualBillEstimateGBP: annualBillEstimateGBP, files: files, emitterDemandStatus: emitterDemandStatus, totalHeatLossKw: totalHeatLossKw, companyUuid: lead.company_uuid, setFiles: setFiles, setSurvey: setSurvey, isOffline: isOffline, allSynced: allSynced })
        }, {
            path: /design\/cylinders/,
            component: () => _jsx(CylinderDesignPage, { files: files, setFiles: setFiles, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, survey: survey, setSurvey: setSurvey, cylinderReheatRow: cylinderReheatRow, hotWaterRowNormal: hotWaterRowNormal, hotWaterRowLegionella: hotWaterRowLegionella, design: design, setDesign: setDesign, hotWaterCylinders: hotWaterCylinders, currentHotWaterCylinderId: design.current_hot_water_cylinder_uuid, companyUUID: lead.company_uuid, isOffline: isOffline, allSynced: allSynced })
        }, {
            path: /design\/pipework/,
            component: () => _jsx(PipeworkDesignPage, { flowTempC: design.flow_temp, deltaTFlowReturnC: design.delta_t_flow_return_c, design: design, setDesign: setDesign, survey: survey, pipeDataList: pipeDataList, designedEmitters: designedEmitters, indexEmitterUUID: indexEmitterUUID, secondaryEmitterUUIDS: secondaryEmitterUUIDS, secondaryEmitterUUIDsThroughReplacement: secondaryEmitterUUIDsThroughReplacement, isOffline: isOffline, allSynced: allSynced })
        }, {
            path: /design\/performance/,
            component: () => _jsx(PerformanceDesignPage, { survey: survey, setSurvey: setSurvey, postcode: lead.property.postcode, performanceEstimateColumns: performanceEstimateColumns, performanceEstimateSummary: performanceEstimateSummary, degreeDays: degreeDays, heatLossWattsPerKelvin: heatLossWattsPerKelvin, scopSpaceHeating: scopSpaceHeating, scopHotWater: scopHotWater, isOffline: isOffline, allSynced: allSynced })
        }];
    const page = ROUTES.find(x => x.path.test(currentPathWithoutBase));
    if (page)
        return page.component();
    if (!hasPermission({
        user: adminContext.data.user,
        company: hydratedCompany,
        permissions,
        permissionPath: 'jobs.*',
        job: lead
    })) {
        return _jsxs("div", { className: 'flex flex-col', children: [_jsx(PageHeader, { onBack: () => navigateTo('/admin/enquiries'), title: 'Unauthorised', isOffline: isOffline }), _jsx(UnauthorizedPage, {})] });
    }
    // Base
    return _jsx(TabbedDashboardLayout, { permissions: permissions, onBack: () => navigateTo('/admin/enquiries'), navigateTo: navigateTo, title: lead.job_name || getAddressIncludingPostcode(lead), subtitle: lead.job_name ? getAddressIncludingPostcode(lead) : undefined, basePath: `/admin/quotes/${leadUUID}`, selectedTabId: tab, selectedSecondaryTabId: secondaryTab, isOffline: adminContext.isOffline, allSynced: leadSaved, lead: lead, company: hydratedCompany, setLead: setLead, stages: stages, tabs: compact([
            hasPermission({ user: adminContext.data.user, company: hydratedCompany, permissions, permissionPath: 'jobs.estimates.view_and_edit', job: lead }) && {
                id: 'estimate',
                label: 'Estimate',
                permission: 'jobs.estimates.view_and_edit',
                content: _jsx(EnquiryManagerDashboard, { company: hydratedCompany, permissions: permissions, lead: lead, setLead: setLead, estimate: estimate, isOffline: adminContext.isOffline, navigateTo: navigateTo, setEstimates: setEstimates, stages: stages }),
                secondaryTabs: [
                    {
                        id: 'dashboard',
                        label: 'Dashboard',
                        content: _jsx(EnquiryManagerDashboard, { company: hydratedCompany, permissions: permissions, navigateTo: navigateTo, lead: lead, setLead: setLead, estimate: estimate, isOffline: adminContext.isOffline, setEstimates: setEstimates, stages: stages })
                    },
                    {
                        id: 'enquiry-data',
                        label: 'Enquiry data',
                        content: _jsx(EnquiryData, { lead: lead, setLead: setLead, estimate: estimate })
                    },
                    {
                        id: 'history',
                        label: 'History',
                        content: _jsx(EnquiryHistory, { company: hydratedCompany, lead: lead, estimate: estimate, calculatedEstimates: estimates, reloadTrigger: loadData })
                    },
                    {
                        id: 'cost-estimate',
                        label: 'Edit cost estimate',
                        content: _jsx(CostEstimateEditor, { company: hydratedCompany, navigateTo: navigateTo, companyPublicInfo: company.public_info, estimate: estimate, lead: lead, setLead: setLead }),
                        hidden: true
                    },
                    {
                        id: 'preview',
                        label: 'Preview',
                        content: _jsx(EstimatePreview, { company: hydratedCompany, companyPublicInfo: companyPublicInfo, estimate: estimate, lead: lead })
                    }
                ]
            },
            hasPermission({ user: adminContext.data.user, company: hydratedCompany, permissions, permissionPath: 'jobs.surveys.view_and_edit', job: lead }) && {
                id: 'survey',
                label: 'Survey',
                permission: 'jobs.surveys.view_and_edit',
                content: _jsx(Survey, { company: hydratedCompany, lead: lead, survey: survey, navigateTo: navigateToWithBasePath, adminContext: adminContext, leadUUID: leadUUID, setCustomMaterials: setCustomMaterials, customMaterials: customMaterials, surveyDB: surveyDB, useFloorplanFlow: useFloorplanFlow })
            },
            hasPermission({ user: adminContext.data.user, company: hydratedCompany, permissions, permissionPath: 'jobs.surveys.view_and_edit', job: lead }) && {
                id: 'design',
                label: 'Design',
                permission: 'jobs.surveys.view_and_edit',
                isDisabled: !surveyDB,
                content: _jsx(DesignHome, { design: design, setDesign: setDesign, soundAssessment: soundAssessment, currentHeatPump: currentHeatPump, currentHotWaterCylinder: currentHotWaterCylinder, heatPumpCapacityResult: heatPumpCapacityResult, totalHeatLossKwSurvey: totalHeatLossKw, annualBillEstimateGBP: annualBillEstimateGBP, reheatTimeHoursAndMinutes: cylinderReheatRow?.reheatTimeHoursAndMinutes ?? '', hotWaterConsumptionAnnual: hotWaterConsumptionAnnual, performanceEstimateColumns: performanceEstimateColumns, hddPerformanceEstimate: performanceEstimateSummary.hddEstimate, emitterColumns: emitterColumns, emitterRows: emitterRows, scop: scopSpaceHeating, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, emitterDemandStatus: emitterDemandStatus, pipeData: pipeDataList, navigateTo: navigateToWithBasePath })
            },
            hasPermission({ user: adminContext.data.user, company: hydratedCompany, permissions, permissionPath: 'jobs.reports.send_and_generate', job: lead }) && {
                id: 'report',
                label: 'Report',
                permission: 'jobs.reports.send_and_generate',
                isDisabled: !surveyDB || !surveyDB.data.designs.every(x => x.current_heat_pump_uuid && x.current_hot_water_cylinder_uuid),
                content: _jsx(HeatLossReportSettings, { company: hydratedCompany, lead: lead, setLead: setLead, survey: survey, files: files, currentHeatPump: currentHeatPump, currentHotWaterCylinder: currentHotWaterCylinder, navigateTo: navigateToWithBasePath })
            },
            hasPermission({ user: adminContext.data.user, company: hydratedCompany, permissions, permissionPath: 'jobs.proposals.view_and_edit', job: lead }) && {
                id: 'proposal',
                label: 'Proposal',
                permission: 'jobs.proposals.view_and_edit',
                isDisabled: !surveyDB || !surveyDB.data.designs.every(x => x.current_heat_pump_uuid && x.current_hot_water_cylinder_uuid),
                content: _jsx(ProposalConfigurator, { company: hydratedCompany, currentPathWithoutBase: currentPathWithoutBase, currentHeatPump: currentHeatPump, currentHotWaterCylinder: currentHotWaterCylinder, 
                    // Designed radiators only - we don't want survey raditors in the quote
                    emitters: designedEmitters.filter(x => x.survey_or_design === 'DESIGN'), navigateTo: navigateToWithBasePath, lead: lead, survey: survey, files: files, setLead: setLead, companyPublicInfo: company.public_info, stages: stages })
            },
            hasPermission({ user: adminContext.data.user, company: hydratedCompany, permissions, permissionPath: 'jobs.invite', job: lead }) && {
                id: 'invites',
                label: 'Invites',
                permission: 'jobs.invite',
                isDisabled: !surveyDB,
                content: _jsx(Invitations, { company: hydratedCompany, lead: lead, setLead: setLead, title: 'Invite someone to this survey', helpText: 'People you invite to a survey will only have access to that survey, design and report. They will not be able to see the estimate, or your settings and cost and inventory.' })
            },
            hasPermission({ user: adminContext.data.user, company: hydratedCompany, permissions, permissionPath: 'jobs.estimates.view_and_edit', job: lead }) && {
                id: 'history',
                label: 'History',
                permission: 'jobs.estimates.view_and_edit',
                content: _jsx(JobHistory, { company: hydratedCompany, lead: lead, stages: stages })
            }
        ]) });
};
