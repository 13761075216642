import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { SettingsLayout } from '../../components/settings_layout';
import { Section } from '../../../../../components/containers/section';
import { TableLite } from '../../../../../components/content_display/table_lite';
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu';
import { PencilIcon, PlusIcon, TrashIcon } from 'lucide-react';
import { Button } from '../../../../../components/buttons/button';
import { updateCompanyRole, deleteCompanyRole, insertCompanyRole, insertCompanyRolePermission, deleteCompanyRolePermission } from '../../../../../code/models/company_roles';
import { db } from '../../../../heat_loss/db';
import { EditCompanyRole } from './edit_company_role';
import { chain } from 'lodash';
export const RolesManagement = ({ permissions, companyUUID, company, userCompanies, userJobs }) => {
    const [editingCompanyRole, setEditingCompanyRole] = useState();
    const NEW_COMPANY_ROLE = {
        company_uuid: companyUUID,
        name: '',
        company_role_permissions: [],
        description: ''
    };
    const uniqueUsersByRole = chain([...userCompanies, ...userJobs])
        .groupBy(x => x.company_role_uuid)
        .map((values, key) => ({
        company_role_uuid: key,
        length: [...new Set(values.map(x => x.user_uuid))].length
    }))
        .value();
    const handleAddCompanyRole = async () => {
        const insertedCompanyRole = { ...editingCompanyRole, uuid: crypto.randomUUID() };
        const insertedCompanyRolePermissions = insertedCompanyRole.company_role_permissions.map(x => ({ ...x, company_role_uuid: insertedCompanyRole.uuid }));
        await insertCompanyRole(insertedCompanyRole);
        await Promise.all(insertedCompanyRolePermissions.map(async (x) => await insertCompanyRolePermission(companyUUID, x)));
        await db.company_roles.add(insertedCompanyRole);
        await db.company_role_permissions.bulkAdd(insertedCompanyRolePermissions);
    };
    const handleUpdateCompanyRole = async () => {
        const existingRow = company.company_roles.find(x => x.uuid === editingCompanyRole.uuid);
        const addedPermissions = editingCompanyRole.company_role_permissions.filter(x => !existingRow.company_role_permissions.some(y => y.uuid === x.uuid));
        const removedPermissions = existingRow.company_role_permissions.filter(x => !editingCompanyRole.company_role_permissions.some(y => y.uuid === x.uuid));
        await updateCompanyRole(editingCompanyRole);
        await Promise.all(addedPermissions.map(async (x) => await insertCompanyRolePermission(companyUUID, x)));
        await Promise.all(removedPermissions.map(async (x) => await deleteCompanyRolePermission(companyUUID, x.uuid)));
        await db.company_roles.put(editingCompanyRole);
        await db.company_role_permissions.bulkAdd(addedPermissions);
        await db.company_role_permissions.bulkDelete(removedPermissions.map(x => x.uuid));
    };
    const handleAddOrUpdateRole = async () => {
        if (editingCompanyRole?.uuid) {
            handleUpdateCompanyRole();
        }
        else {
            handleAddCompanyRole();
        }
        setEditingCompanyRole(undefined);
    };
    const handleRemoveRole = async (companyRoleUUID) => {
        await deleteCompanyRole(companyUUID, companyRoleUUID);
        await db.company_roles.delete(companyRoleUUID);
    };
    const columns = [
        { name: 'Role', render: (row) => _jsx("div", { children: row.name }) },
        { name: 'Users', render: (row) => _jsx("div", { children: uniqueUsersByRole.find(x => x.company_role_uuid === row.uuid)?.length ?? 0 }) },
        { name: 'Permissions', render: (row) => _jsx("div", { children: row.company_role_permissions?.length ?? 0 }) },
        {
            name: '',
            render: (row) => _jsx("div", { className: 'flex justify-end', children: _jsx(DropdownMenu, { items: [
                        { label: 'Edit permissions', onClick: () => setEditingCompanyRole(row), icon: PencilIcon },
                        {
                            label: 'Delete role',
                            disabled: (uniqueUsersByRole.find(x => x.company_role_uuid === row.uuid)?.length ?? 0) !== 0,
                            onClick: () => handleRemoveRole(row.uuid),
                            icon: TrashIcon,
                            confirmText: 'Are you sure you want to remove this role from the company?'
                        }
                    ] }) })
        }
    ];
    return (_jsxs(_Fragment, { children: [_jsx(SettingsLayout, { heading: 'Roles', description: 'Manage the permissions for your users', actions: _jsx(Button, { colour: 'DARK', onClick: () => setEditingCompanyRole(NEW_COMPANY_ROLE), iconLeft: PlusIcon, children: "Add role" }), children: _jsx(Section, { title: "Roles", border: true, children: _jsx(TableLite, { columns: columns, rows: company.company_roles, alignRight: false }) }) }), editingCompanyRole && _jsx(EditCompanyRole, { editingRole: editingCompanyRole, permissions: permissions, onSave: handleAddOrUpdateRole, setEditingRole: setEditingCompanyRole, setVisible: () => setEditingCompanyRole(undefined) })] }));
};
