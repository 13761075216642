import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Modal } from '../containers/modal';
import { Icon } from './icon';
const colours = {
    TRANSPARENT: 'bg-transparent border-transparent text-gray-600 font-bold',
    DARK: 'bg-gray-900 text-white border border-gray-900 text-white font-bold rounded-md',
    LIGHT: 'bg-white border border-gray-300 font-bold rounded-md hover:bg-gray-50',
    GRAY: 'bg-gray-50 border border-gray-50 rounded-md hover:bg-gray-100',
    LOZENGE_LIGHT: 'bg-gray-100 rounded-full font-bold',
    LOZENGE_DARK: 'bg-gray-200 rounded-full font-bold'
};
const iconColour = {
    TRANSPARENT: 'text-default',
    DARK: 'text-white',
    LIGHT: 'text-default',
    GRAY: 'text-default',
    LOZENGE_LIGHT: 'text-default',
    LOZENGE_DARK: 'text-default'
};
const sizes = {
    SM: 'text-xs py-2 px-2.5',
    MD: 'text-sm px-4 py-2',
    LG: 'px-4 py-3'
};
export const Button = ({ children, onClick, block, disabled, size = 'MD', className, colour = 'DARK', iconLeft, iconRight, confirmText, style, ...rest }) => {
    const [modalOpen, setModalOpen] = useState(false);
    return _jsxs(_Fragment, { children: [_jsx("button", { disabled: disabled, onClick: confirmText ? () => setModalOpen(true) : onClick, className: `${colours[colour]} ${sizes[size]} disabled:opacity-50 cursor-pointer items-center justify-center flex disabled:cursor-default outline-none ${block ? 'block w-full' : ''} ${className}`, style: style, ...rest, children: _jsxs("div", { className: `${children ? 'gap-2' : ''} flex items-center`, children: [iconLeft && _jsx(Icon, { colour: iconColour[colour], icon: iconLeft }), children, iconRight && _jsx(Icon, { colour: iconColour[colour], icon: iconRight })] }) }), confirmText && modalOpen && _jsx(Modal, { onConfirm: onClick, visible: true, title: confirmText, setVisible: () => setModalOpen(!modalOpen), children: "This action cannot be undone." })] });
};
