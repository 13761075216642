import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { Section } from '../components/section';
import { contextSensitiveFormatDate } from '../../../../code/format_date';
import { getJobEvents, JobEventType } from '../../../../code/models/job_event';
import { DropdownMenu } from '../../../../components/buttons/dropdown_menu';
import { openInNewTab } from '../../../../code/helpers';
import { Select } from '../../../../components/inputs_and_selections/select';
import { Filter } from 'lucide-react';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { AdminContext } from '../../admin_layout';
import { UnauthorizedPage } from '../../unauthorized_page';
const HistoryItemDescription = ({ event, stages }) => {
    const renderUserInfo = () => {
        const elements = [];
        if (event.user_name) {
            elements.push(_jsx("strong", { className: "text-bold font-semibold", children: event.user_name }, "user"), ' ');
        }
        if (event.company_name) {
            elements.push(_jsxs("strong", { className: "text-bold font-semibold", children: ["(", event.company_name, ")"] }, "company"), ' ');
        }
        return elements;
    };
    const renderRecipients = () => {
        if (event.extra_data?.recipients && Array.isArray(event.extra_data.recipients)) {
            return _jsxs("strong", { className: "text-bold font-semibold", children: [" to ", event.extra_data.recipients.join(', ')] });
        }
        return '';
    };
    switch (event.event_type) {
        // Actions without a user
        case JobEventType.enum.JobCreated:
            return _jsx(_Fragment, { children: "Job created" });
        // Actions where the user is anonymous
        case JobEventType.enum.EstimateRejected:
            return _jsx(_Fragment, { children: "Estimate was rejected by customer" });
        case JobEventType.enum.EstimateAccepted:
            return _jsx(_Fragment, { children: "Estimate was accepted by customer" });
        case JobEventType.enum.EstimateCallbackRequested:
            return _jsx(_Fragment, { children: "A callback was requested by customer" });
        case JobEventType.enum.EstimateOpened:
            return _jsx(_Fragment, { children: "Estimate was opened by customer" });
        case JobEventType.enum.ReportOpened:
            return _jsx(_Fragment, { children: "Report was opened by customer" });
        case JobEventType.enum.ProposalOpened:
            return _jsx(_Fragment, { children: "Proposal was opened by customer" });
        case JobEventType.enum.QuoteOpened:
            return _jsx(_Fragment, { children: "Quote was opened by customer" });
        case JobEventType.enum.ProposalAccepted:
            return _jsx(_Fragment, { children: "Proposal was accepted by customer" });
        // Actions which sometimes have a user
        case JobEventType.enum.StatusChanged:
            const statuses = stages.flatMap(x => x.statuses);
            const oldStatus = statuses.find(x => x.uuid === event.extra_data?.old_status);
            const newStatus = statuses.find(x => x.uuid === event.extra_data?.new_status);
            if (event.user_name) {
                return (_jsxs("div", { children: [renderUserInfo(), "changed the status from", ' ', oldStatus ? _jsx(Badge, { color: oldStatus.color, text: oldStatus.name }) : _jsx("span", { children: event.extra_data?.old_status }), ' ', "to", ' ', newStatus ? _jsx(Badge, { color: newStatus.color, text: newStatus.name }) : _jsx("span", { children: event.extra_data?.new_status })] }));
            }
            else {
                return (_jsxs("div", { children: ["Status changed from", ' ', oldStatus ? _jsx(Badge, { color: oldStatus.color, text: oldStatus.name }) : _jsx("span", { children: event.extra_data?.old_status }), ' ', "to", ' ', newStatus ? _jsx(Badge, { color: newStatus.color, text: newStatus.name }) : _jsx("span", { children: event.extra_data?.new_status })] }));
            }
        // Actions with a user
        case JobEventType.enum.ReportPDFGenerated:
            return (_jsxs(_Fragment, { children: [renderUserInfo(), "downloaded a report PDF"] }));
        case JobEventType.enum.ProposalPDFGenerated:
            return (_jsxs(_Fragment, { children: [renderUserInfo(), "downloaded a proposal PDF"] }));
        case JobEventType.enum.QuotePDFGenerated:
            return (_jsxs(_Fragment, { children: [renderUserInfo(), "downloaded a quote PDF"] }));
        case JobEventType.enum.EstimateSent:
            return (_jsxs(_Fragment, { children: [renderUserInfo(), "sent an estimate", renderRecipients()] }));
        case JobEventType.enum.EstimateReminderSent:
            return (_jsxs(_Fragment, { children: [renderUserInfo(), "sent an estimate reminder", renderRecipients()] }));
        case JobEventType.enum.EnquiryDeclined:
            return (_jsxs(_Fragment, { children: [renderUserInfo(), "declined the enquiry"] }));
        case JobEventType.enum.ReportSent:
            return (_jsxs(_Fragment, { children: [renderUserInfo(), "sent a report", renderRecipients()] }));
        case JobEventType.enum.ProposalSent:
            return (_jsxs(_Fragment, { children: [renderUserInfo(), "sent a proposal", renderRecipients()] }));
        case JobEventType.enum.QuoteSent:
            return (_jsxs(_Fragment, { children: [renderUserInfo(), "sent a quote", renderRecipients()] }));
        default:
            return _jsx(_Fragment, { children: "Unknown event" });
    }
};
const actionsForEvent = (event, subdomain) => {
    switch (event.event_type) {
        case JobEventType.enum.EstimateSent:
        case JobEventType.enum.EstimateReminderSent:
        case JobEventType.enum.EstimateRejected:
        case JobEventType.enum.EstimateAccepted:
        case JobEventType.enum.EstimateCallbackRequested:
            return [
                { label: 'View estimate', onClick: () => openInNewTab(`/${subdomain}/estimate/${event.extra_data?.calculated_estimate_uuid}?installer=true`) }
            ];
        case JobEventType.enum.ReportSent:
        case JobEventType.enum.ReportOpened:
        case JobEventType.enum.ReportPDFGenerated:
            return [
                { label: 'View report', onClick: () => openInNewTab(`/${subdomain}/report/${event.extra_data?.report_uuid}?installer=true`) }
            ];
        case JobEventType.enum.ProposalSent:
        case JobEventType.enum.ProposalOpened:
        case JobEventType.enum.ProposalPDFGenerated:
        case JobEventType.enum.ProposalAccepted:
            return [
                { label: 'View proposal', onClick: () => openInNewTab(`/${subdomain}/proposal/${event.extra_data?.proposal_uuid}?installer=true`) }
            ];
        case JobEventType.enum.QuoteSent:
        case JobEventType.enum.QuoteOpened:
        case JobEventType.enum.QuotePDFGenerated:
            return [
                { label: 'View quote', onClick: () => openInNewTab(`/${subdomain}/quote/${event.extra_data?.quote_uuid}?installer=true`) }
            ];
        default:
            return [];
    }
};
export const JobHistory = ({ company, lead, stages }) => {
    const [jobHistory, setJobHistory] = useState([]);
    const adminContext = useContext(AdminContext);
    const filterOptions = [
        { key: 'job-events', value: 'Job events' },
        { key: 'status-updates', value: 'Status updates' }
    ];
    const [activeFilters, setActiveFilters] = useState(filterOptions.map(x => x.key));
    const hasPermission = !adminContext.isLoading && adminContext.userHasCompanyPermission('jobs.estimates.view_and_edit', lead);
    useEffect(() => {
        const fetchEvents = async () => {
            if (!hasPermission) {
                return;
            }
            const events = await getJobEvents(lead.uuid, company.uuid);
            if (events) {
                setJobHistory(events);
            }
        };
        // Initial render
        fetchEvents();
        // Poll for new events every 10 seconds
        const intervalId = setInterval(fetchEvents, 10000);
        // Cleanup function
        return () => clearInterval(intervalId);
    }, [lead.uuid]);
    if (!adminContext.isLoading && !adminContext.userHasCompanyPermission('jobs.estimates.view_and_edit', lead)) {
        return _jsx(UnauthorizedPage, {});
    }
    const eventsWithoutDeprecatedTypes = jobHistory.filter(x => x.event_type !== JobEventType.enum.DEPRECATED_ReportGenerated && x.event_type !== JobEventType.enum.DEPRECATED_ProposalGenerated && x.event_type !== JobEventType.enum.DEPRECATED_QuoteGenerated);
    const filteredEvents = eventsWithoutDeprecatedTypes?.filter(x => {
        if (activeFilters.includes('job-events') && x.event_type !== JobEventType.enum.StatusChanged)
            return true;
        if (activeFilters.includes('status-updates') && x.event_type === JobEventType.enum.StatusChanged)
            return true;
        return false;
    });
    return (_jsxs("div", { className: 'flex flex-col gap-6', children: [_jsxs("div", { className: 'flex flex-col md:flex-row md:justify-between md:items-center gap-2', children: [_jsx("div", { className: "text-gray-900 text-xl font-bold", children: "Job history" }), _jsx("div", { className: 'w-52', children: _jsx(Select, { icon: Filter, multiple: true, options: filterOptions, selectedKey: activeFilters, setSelectedKey: setActiveFilters, placeholder: 'Filter events' }) })] }), _jsx(Section, { children: filteredEvents?.length && filteredEvents.length > 0
                    ? (_jsx("ul", { children: filteredEvents
                            ?.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
                            .map(x => _jsxs("li", { className: "text-sm text-default flex flex-col md:flex-row md:justify-between min-h-10 px-1 py-2 hover:bg-gray-50 border-b border-gray-200", children: [_jsxs("div", { children: [_jsx("span", { className: "text-gray-400", children: contextSensitiveFormatDate(x.created_at) }), " ", _jsx("span", { className: 'hidden md:inline', children: "\u00B7" }), " ", _jsx("span", { className: 'inline-flex flex-wrap gap-1 items-center', children: _jsx(HistoryItemDescription, { stages: stages, event: x }) })] }), actionsForEvent(x, company.public_info.subdomain).length > 0 ? _jsx("div", { className: 'hidden md:block', children: _jsx(DropdownMenu, { items: actionsForEvent(x, company.public_info.subdomain) }) }) : _jsx(_Fragment, {})] }, x.uuid)) }))
                    : _jsx("div", { className: "text-gray-400 text-sm text-center", children: "No events to show" }) })] }));
};
