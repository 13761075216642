import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useContext, useState } from 'react';
import { AdminContext } from '../admin_layout';
import { OfflinePage } from '../offline_page';
import { updateCompanySettings } from '../../../code/models/company';
import TabbedDashboardLayout from '../../../layouts/tabbed_dashboard_layout';
import { CompanyDetails } from './company_settings/company_details';
import { EmailTemplatesSendEstimate } from './email_templates_settings/email_templates_send_estimate';
import { EstimateEnquiryForm } from './estimate_settings/estimate_enquiry_form';
import { AccountIntegrations } from './company_settings/account_integrations';
import { AccountBranding } from './company_settings/account_branding';
import { EstimateResultsPage } from './estimate_settings/estimate_results_page';
import { EmailTemplatesNudgeFollowup } from './email_templates_settings/email_templates_nudge_follow-up';
import { EmailTemplatesBookSurvey } from './email_templates_settings/email_templates_book_survey';
import { EmailTemplatesDeclineEnquiry } from './email_templates_settings/email_templates_decline_enquiry';
import { useDebounceCallback } from 'usehooks-ts';
import { EstimatePreferences } from './estimate_settings/estimate_preferences';
import { AccountTools } from './company_settings/account_tools';
import { EmailTemplatesReport } from './email_templates_settings/email_templates_report';
import { EmailTemplatesNewQuote } from './email_templates_settings/email_templates_new_quote';
import { ProposalTemplates } from './proposal_settings/proposal_templates';
import { EmailTemplatesNewProposal } from './email_templates_settings/email_templates_new_proposal';
import { ProposalAttachments } from './proposal_settings/proposal_attachments';
import { PersonalAccount } from './personal_account';
import { updateUser } from '../../../code/models/user';
import { Loader } from '../../../components/indicators_and_messaging/loader';
import { compact } from 'lodash';
import { RolesManagement } from './company_settings/role_management/roles_management';
import { UserManagement } from './company_settings/user_management';
import { db } from '../../heat_loss/db';
import { OrganisationsManagement } from './company_settings/organisations_management/organisations_management';
export const SettingsPage = ({ tab, secondaryTab, navigateTo, company, permissions }) => {
    const adminContext = useContext(AdminContext);
    const [settingsSaved, setSettingsSaved] = useState(true);
    const debouncePatchSettings = useDebounceCallback(async (company) => {
        try {
            await updateCompanySettings(company);
            setSettingsSaved(true);
        }
        catch (e) {
            console.error(e);
        }
    }, 1000);
    const debounceUpdateUser = useDebounceCallback(async (user) => {
        try {
            await updateUser(user);
            setSettingsSaved(true);
        }
        catch (e) {
            console.error(e);
        }
    }, 1000);
    const setCompany = useCallback(async (company) => {
        setSettingsSaved(false);
        await db.companies.put(company);
        debouncePatchSettings(company);
    }, []);
    const setUser = useCallback((user) => {
        setSettingsSaved(false);
        adminContext.setUser(user);
        debounceUpdateUser(user);
    }, []);
    if (adminContext.isOffline) {
        return _jsx(OfflinePage, { navigateTo: navigateTo });
    }
    if (adminContext.isLoading) {
        return (_jsx("div", { className: 'flex justify-center items-center h-full', children: _jsx(Loader, {}) }));
    }
    return (_jsx(TabbedDashboardLayout, { company: company, permissions: permissions, navigateTo: navigateTo, title: 'Settings', basePath: '/admin/settings', selectedTabId: tab, selectedSecondaryTabId: secondaryTab, isOffline: adminContext.isOffline, allSynced: settingsSaved, tabs: compact([
            {
                id: 'personal-account',
                label: 'Personal account',
                secondaryTabs: [
                    {
                        id: 'personal-details',
                        label: 'Personal details',
                        content: _jsx(PersonalAccount, { user: adminContext.data.user, setUser: setUser })
                    },
                    {
                        id: 'developer-tools',
                        label: 'Developer tools',
                        content: _jsx(AccountTools, {})
                    }
                ]
            },
            adminContext.userHasCompanyPermission('settings.company.view_and_edit') && {
                id: 'company',
                label: 'Company',
                permission: 'settings.company.view_and_edit',
                secondaryTabs: compact([
                    {
                        id: 'company-details',
                        label: 'Company details',
                        content: _jsx(CompanyDetails, { company: company, setCompany: setCompany })
                    },
                    adminContext.userHasCompanyPermission('settings.users.view_and_edit') && {
                        id: 'people',
                        label: 'People',
                        permission: 'settings.users.view_and_edit',
                        content: _jsx(UserManagement, { company: company, setCompany: setCompany })
                    },
                    adminContext.userHasCompanyPermission('settings.roles.view_and_edit') && {
                        id: 'roles',
                        label: 'Roles',
                        permission: 'settings.roles.view_and_edit',
                        content: _jsx(RolesManagement, { companyUUID: company.uuid, permissions: permissions, company: company, userCompanies: adminContext.data.companyUsers ?? [], userJobs: adminContext.data.companyJobUsers ?? [] })
                    },
                    adminContext.userHasCompanyPermission('settings.organisations.view_and_edit') && {
                        id: 'organisations',
                        label: 'Organisations',
                        permission: 'settings.organisations.view_and_edit',
                        content: _jsx(OrganisationsManagement, { companyUUID: company.uuid })
                    },
                    {
                        id: 'branding',
                        label: 'Branding',
                        content: _jsx(AccountBranding, { company: company, setCompany: setCompany })
                    },
                    {
                        id: 'integrations',
                        label: 'Integrations',
                        content: _jsx(AccountIntegrations, { company: company, setCompany: setCompany })
                    }
                ])
            },
            adminContext.userHasCompanyPermission('settings.company.view_and_edit') && {
                id: 'estimate',
                label: 'Estimate',
                permission: 'settings.company.view_and_edit',
                secondaryTabs: [
                    {
                        id: 'enquiry-form',
                        label: 'Enquiry form',
                        content: _jsx(EstimateEnquiryForm, { company: company, setCompany: setCompany })
                    },
                    {
                        id: 'estimate-preferences',
                        label: 'Estimate preferences',
                        content: _jsx(EstimatePreferences, { company: company, setCompany: setCompany })
                    },
                    {
                        id: 'results-page',
                        label: 'Results page',
                        content: _jsx(EstimateResultsPage, { company: company, setCompany: setCompany })
                    }
                ]
            },
            adminContext.userHasCompanyPermission('settings.company.view_and_edit') && {
                id: 'proposal',
                label: 'Proposal',
                permission: 'settings.company.view_and_edit',
                secondaryTabs: [
                    {
                        id: 'templates',
                        label: 'Templates',
                        content: _jsx(ProposalTemplates, { company: company, setCompany: setCompany })
                    },
                    {
                        id: 'proposal-attachments',
                        label: 'Attachments',
                        content: _jsx(ProposalAttachments, { company: company })
                    }
                ]
            },
            adminContext.userHasCompanyPermission('settings.company.view_and_edit') && {
                id: 'email-templates',
                label: 'Email templates',
                permission: 'settings.company.view_and_edit',
                secondaryTabs: [
                    // {
                    //   id: 'enquiry-received',
                    //   label: 'Enquiry received',
                    //   content: <EmailTemplatesEnquiryReceived
                    //     company={company}
                    //     setCompany={setCompany}
                    //   />
                    // },
                    {
                        id: 'send-estimate',
                        label: 'Send estimate',
                        content: _jsx(EmailTemplatesSendEstimate, { company: company, setCompany: setCompany })
                    },
                    {
                        id: 'nudge-follow-up',
                        label: 'Nudge follow-up',
                        content: _jsx(EmailTemplatesNudgeFollowup, { company: company, setCompany: setCompany })
                    },
                    {
                        id: 'book-survey',
                        label: 'Book survey',
                        content: _jsx(EmailTemplatesBookSurvey, { company: company, setCompany: setCompany })
                    },
                    {
                        id: 'decline-enquiry',
                        label: 'Decline enquiry',
                        content: _jsx(EmailTemplatesDeclineEnquiry, { company: company, setCompany: setCompany })
                    },
                    {
                        id: 'report',
                        label: 'Send report',
                        content: _jsx(EmailTemplatesReport, { company: company, setCompany: setCompany })
                    },
                    {
                        id: 'new-quote',
                        label: 'Send quote',
                        content: _jsx(EmailTemplatesNewQuote, { company: company, setCompany: setCompany })
                    },
                    {
                        id: 'new-proposal',
                        label: 'Send proposal',
                        content: _jsx(EmailTemplatesNewProposal, { company: company, setCompany: setCompany })
                    }
                ]
            }
        ]) }));
};
