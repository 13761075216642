import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { orderBy } from 'lodash';
import { Copy } from 'lucide-react';
import { bulkPatchLead } from '../../code/models/lead';
import { useState } from 'react';
import { Badge } from '../../components/indicators_and_messaging/badge';
import { contextSensitiveFormatDate } from '../../code/format_date';
import { DropdownMenu } from '../../components/buttons/dropdown_menu';
import { Select } from '../../components/inputs_and_selections/select';
import { Button } from '../../components/buttons/button';
import { createJobEvent, JobEventType } from '../../code/models/job_event';
export const JobBoard = ({ stages, jobs, setLead, setLeads, setLeadToDuplicate, toggleDuplicateLeadModalVisibility, stageOrStatuses, stageFilterUUID, navigateTo, basePath, companyUUID }) => {
    const orderedJobs = orderBy(jobs, ['order_by', 'createdAt'], ['asc', 'desc']);
    const columns = stageOrStatuses === 'STAGE'
        ? stages.map(x => ({ ...x, type: 'STAGE' }))
        : stages.find(x => x.uuid === stageFilterUUID).statuses.map(x => ({ ...x, type: 'STATUS' }));
    const onDragEnd = async (result) => {
        const { destination, source } = result;
        if (!destination)
            return;
        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return;
        const startColumn = columns.find(x => x.uuid === source.droppableId);
        const finishColumn = columns.find(x => x.uuid === destination.droppableId);
        const startColumnCards = [...orderedJobs.filter(x => startColumn?.type === 'STAGE'
                ? startColumn.statuses.some(y => y.uuid === x.status_uuid)
                : startColumn.uuid === x.status_uuid)];
        // Moving within the same column
        if (startColumn?.uuid === finishColumn?.uuid) {
            const newCardOrder = [...startColumnCards];
            const [movedCard] = newCardOrder.splice(source.index, 1);
            newCardOrder.splice(destination.index, 0, movedCard);
            const updatedCards = newCardOrder.map((x, i) => ({ ...x, order_by: i }));
            bulkPatchLead({ leads: updatedCards, companyUUID });
            setLeads(prev => [
                ...prev.filter(x => !updatedCards.some(y => y.uuid === x.uuid)),
                ...updatedCards
            ]);
            return;
        }
        // Moving to a different column
        const endColumnCards = [...orderedJobs.filter(x => finishColumn?.type === 'STAGE'
                ? finishColumn.statuses.some(y => y.uuid === x.status_uuid)
                : finishColumn.uuid === x.status_uuid)];
        const startColumnOrder = [...startColumnCards];
        const [movedCard] = startColumnOrder.splice(source.index, 1);
        if (finishColumn && (startColumn?.uuid !== finishColumn.uuid)) {
            const newStatus = finishColumn.type === 'STAGE' ? finishColumn.statuses[0].uuid : finishColumn.uuid;
            createJobEvent({
                event_type: JobEventType.enum.StatusChanged,
                company_uuid: companyUUID,
                job_uuid: movedCard.uuid,
                extra_data: {
                    old_status: movedCard.status_uuid,
                    new_status: newStatus
                }
            });
        }
        const updatedStart = startColumnOrder.map((x, i) => ({ ...x, order_by: i }));
        const endColumnOrder = [...endColumnCards];
        endColumnOrder.splice(destination.index, 0, { ...movedCard, status_uuid: finishColumn.type === 'STAGE' ? finishColumn.statuses[0].uuid : finishColumn.uuid });
        const updatedEnd = endColumnOrder.map((x, i) => ({ ...x, order_by: i }));
        const allUpdated = [...updatedStart, ...updatedEnd];
        bulkPatchLead({ leads: allUpdated, companyUUID });
        setLeads(prev => [
            ...prev.filter(x => !allUpdated.some(y => y.uuid === x.uuid)),
            ...allUpdated
        ]);
    };
    return (_jsx(DragDropContext, { onDragEnd: onDragEnd, children: _jsx("div", { className: 'flex gap-4 flex-grow overflow-x-auto', children: orderBy(columns, x => x.order).map((stageOrStatus) => {
                const stageJobs = orderedJobs.filter(x => stageOrStatus.type === 'STAGE'
                    ? stageOrStatus.statuses.some(y => y.uuid === x.status_uuid)
                    : x.status_uuid === stageOrStatus.uuid);
                return _jsx(Column, { column: stageOrStatus, companyUUID: companyUUID, jobs: stageJobs, stages: stages, setLead: setLead, toggleDuplicateLeadModalVisibility: toggleDuplicateLeadModalVisibility, setLeadToDuplicate: setLeadToDuplicate, navigateTo: navigateTo, basePath: basePath }, stageOrStatus.uuid);
            }) }) }));
};
const Column = ({ column, companyUUID, jobs, stages, setLead, toggleDuplicateLeadModalVisibility, setLeadToDuplicate, navigateTo, basePath }) => {
    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = 20;
    const paginatedJobs = jobs.slice(0, pageSize * pageNumber);
    return (_jsxs("div", { className: "w-60 p-2 bg-neutral-50 rounded flex-col gap-2 flex flex-shrink-0", children: [_jsx("div", { className: "justify-between flex items-start", children: _jsxs("div", { className: "items-center gap-2 flex", children: [column.type === 'STATUS'
                            ? _jsx(Badge, { text: column.name, color: column.color })
                            : _jsx(Badge, { text: column.name, color: 'LIGHT' }), _jsx("div", { className: "text-center text-gray-500 text-xs font-medium", children: jobs.length })] }) }), _jsx(Droppable, { droppableId: column.uuid, children: (provided) => (_jsxs("div", { className: 'min-h-10 flex-grow flex flex-col gap-2 overflow-y-auto', ...provided.droppableProps, ref: provided.innerRef, children: [paginatedJobs.map((job, index) => (_jsx(Card, { companyUUID: companyUUID, job: job, index: index, stages: stages, setLead: setLead, toggleDuplicateLeadModalVisibility: toggleDuplicateLeadModalVisibility, setLeadToDuplicate: setLeadToDuplicate, navigateTo: navigateTo, basePath: basePath }, job.uuid))), jobs.length > paginatedJobs.length && _jsx(Button, { colour: 'LIGHT', onClick: () => setPageNumber(prev => prev + 1), children: "Load more" }), _jsx("div", { children: provided.placeholder })] })) })] }));
};
const Card = ({ companyUUID, job, index, stages, setLead, toggleDuplicateLeadModalVisibility, setLeadToDuplicate, navigateTo, basePath }) => {
    const status = stages.flatMap(x => x.statuses).find(x => x.uuid === job.status_uuid);
    return (_jsx(Draggable, { draggableId: job.uuid, index: index, children: (provided) => (_jsx("div", { ref: provided.innerRef, ...provided.draggableProps, ...provided.dragHandleProps, "data-cy": "job-card", children: _jsxs("div", { className: "p-3 bg-white rounded shadow border border-gray-200 flex-col gap-3 flex", children: [_jsxs("div", { className: "gap-3 flex items-start justify-between", children: [_jsx("div", { className: "flex-col gap-2 flex", children: _jsxs("div", { onClick: () => navigateTo(`${basePath}/quotes/${job.uuid}`), className: "flex-col flex", children: [_jsx("div", { className: "text-gray-900 text-sm font-bold", children: job.job_name ? job.job_name : job.property.address }), _jsx("div", { className: "text-gray-500 text-xs font-medium", children: job.property.postcode })] }) }), _jsx(DropdownMenu, { items: [{
                                        label: 'Duplicate',
                                        icon: Copy,
                                        onClick: () => {
                                            toggleDuplicateLeadModalVisibility(true);
                                            setLeadToDuplicate(job);
                                        }
                                    }] })] }), _jsx(Select, { colour: status.color, options: stages.map(y => y.statuses.map(z => ({
                            key: z.uuid,
                            value: z.name,
                            group_name: y.name
                        }))).flat(), selectedKey: job.status_uuid, setSelectedKey: (e) => {
                            if (job.status_uuid !== e) {
                                createJobEvent({
                                    event_type: JobEventType.enum.StatusChanged,
                                    company_uuid: companyUUID,
                                    job_uuid: job.uuid,
                                    extra_data: { old_status: job.status_uuid, new_status: e }
                                });
                                setLead({ ...job, status_uuid: e });
                            }
                        } }), _jsx("div", { className: "text-light text-xs font-medium", children: contextSensitiveFormatDate(new Date(job.createdAt)) })] }) })) }));
};
