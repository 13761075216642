import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { AdminContext } from '../../../admin/admin_layout';
import { CheckCircle, ChevronLeft, Menu, RefreshCw, Wifi } from 'lucide-react';
import { WrappedIcon } from '../../../../components/buttons/wrapped_icon';
import { Select } from '../../../../components/inputs_and_selections/select';
import { createJobEvent, JobEventType } from '../../../../code/models/job_event';
export const PageHeader = (props) => {
    return _jsx(_Fragment, { children: _jsx("div", { className: "px-5 pt-4 pb-3 gap-2 flex flex-col border-b border-gray-300 bg-white", children: _jsx(PageHeaderNoPadding, { ...props }) }) });
};
export const PageHeaderNoPadding = ({ title, subtitle, onBack, children, rightHandChildren, completed, isOffline, allSynced, lead, setLead, stages, company }) => {
    const adminContext = useContext(AdminContext);
    const toggleSidebar = () => adminContext.isSidebarOpen
        ? adminContext.hideSidebar()
        : adminContext.showSidebar();
    const statuses = stages?.flatMap(x => x.statuses);
    const status = lead && statuses?.find(x => x.uuid === lead.status_uuid);
    const leadSelect = lead && setLead && status && statuses && _jsx("div", { className: 'flex justify-end', children: _jsx("div", { children: _jsx(Select, { colour: status.color, options: statuses.map(x => ({ key: x.uuid, value: x.name })), selectedKey: lead.status_uuid, setSelectedKey: async (uuid) => {
                    setLead({ ...lead, status_uuid: uuid }, adminContext.data.user);
                    const newStatus = statuses?.find(x => x.uuid === uuid);
                    if (status?.uuid !== newStatus?.uuid) {
                        createJobEvent({
                            event_type: JobEventType.enum.StatusChanged,
                            company_uuid: company?.public_info.uuid,
                            job_uuid: lead.uuid,
                            extra_data: { old_status: status?.uuid, new_status: newStatus?.uuid }
                        });
                    }
                } }) }) });
    return _jsxs(_Fragment, { children: [_jsxs("div", { className: 'flex justify-between gap-4 items-center', children: [_jsxs("div", { className: 'flex gap-4 items-center cursor-pointer', onClick: onBack || toggleSidebar, children: [onBack && _jsx(WrappedIcon, { icon: ChevronLeft, className: 'flex-shrink-0' }), !onBack && _jsx(WrappedIcon, { icon: Menu, "data-cy": 'nav_open', className: 'md:hidden flex-shrink-0' }), _jsxs("div", { className: 'flex flex-col gap-1', children: [_jsxs("div", { className: 'flex gap-4', children: [_jsx("div", { className: 'text-gray-900 text-xl font-bold', children: title }), isOffline
                                                ? _jsx(Badge, { color: 'RED', text: 'Offline', icon: Wifi, dataCy: "sync-status-badge" })
                                                : allSynced
                                                    ? _jsx(Badge, { color: 'GREEN', text: 'Synced', icon: CheckCircle, dataCy: "sync-status-badge" })
                                                    : allSynced === undefined ? '' : _jsx(Badge, { color: "YELLOW", spin: true, icon: RefreshCw, text: "Sync pending...", dataCy: "sync-status-badge" })] }), subtitle && _jsx("div", { className: 'text-light text-xs', children: subtitle })] })] }), _jsxs("div", { className: 'flex gap-2', children: [_jsxs("div", { className: 'flex gap-2 justify-end items-center', children: [rightHandChildren, completed !== undefined && _jsx(Badge, { color: completed ? 'GREEN' : 'LIGHT', text: completed ? 'Complete' : 'Incomplete' })] }), _jsx("div", { className: 'hidden md:flex', children: leadSelect })] })] }), leadSelect && _jsx("div", { className: 'md:hidden', children: leadSelect }), children] });
};
