import { z } from 'zod';
import { axiosDeleteV2, axiosGetV2, axiosPatchV2, axiosPostV2 } from '../axios';
import { parseWithZod } from '../zod';
export const PermissionsValues = [
    'jobs.estimates.view_and_edit',
    'jobs.estimates.send_emails',
    'jobs.surveys.view_and_edit',
    'jobs.designs.view_and_edit',
    'jobs.reports.send_and_generate',
    'jobs.proposals.view_and_edit',
    'jobs.proposals.send_and_generate',
    'jobs.invite',
    'jobs.create',
    'inventories.view',
    'inventories.edit',
    'inventories.create_and_delete',
    'settings.company.view_and_edit',
    'settings.users.view_and_edit',
    'settings.roles.view_and_edit',
    'settings.organisations.view_and_edit'
];
export const PermissionsEnum = z.enum(PermissionsValues);
export const PermissionSchema = z.object({
    uuid: z.string().uuid(),
    path: z.string(),
    name: z.string(),
    parent_uuid: z.string().uuid().optional()
});
export const CompanyRolePermissionSchema = z.object({
    uuid: z.string().uuid().optional(),
    company_role_uuid: z.string().uuid(),
    permission_uuid: z.string(),
    created_at: z.coerce.date().optional(),
    updated_at: z.coerce.date().optional()
});
export const CompanyRoleSchema = z.object({
    uuid: z.string().uuid().optional(),
    company_uuid: z.string().uuid(),
    name: z.string(),
    description: z.string().optional().nullable(),
    company_role_permissions: z.array(CompanyRolePermissionSchema).optional()
});
export const getPermissions = async () => {
    const response = await axiosGetV2('permissions');
    return parseWithZod(z.array(PermissionSchema), response);
};
// Company Roles
export const getCompanyRoles = async (companyUUID) => {
    const response = await axiosGetV2(`teams/${companyUUID}/company_roles`);
    return parseWithZod(z.array(CompanyRoleSchema), response);
};
export const updateCompanyRole = (companyRole) => axiosPatchV2(`teams/${companyRole.company_uuid}/company_roles/${companyRole.uuid}`, getCompanyRolePure(companyRole));
export const insertCompanyRole = (companyRole) => axiosPostV2(`teams/${companyRole.company_uuid}/company_roles`, getCompanyRolePure(companyRole));
export const deleteCompanyRole = (companyUUID, companyRoleUUID) => axiosDeleteV2(`teams/${companyUUID}/company_roles/${companyRoleUUID}`);
// Company Role Permissions
export const getCompanyRolePermissions = async (companyUUID) => {
    const response = await axiosGetV2(`teams/${companyUUID}/company_role_permissions`);
    return parseWithZod(z.array(CompanyRolePermissionSchema), response);
};
export const insertCompanyRolePermission = (companyUUID, companyRolePermission) => axiosPostV2(`teams/${companyUUID}/company_role_permissions`, companyRolePermission);
export const deleteCompanyRolePermission = (companyUUID, companyRolePermissionUUID) => axiosDeleteV2(`teams/${companyUUID}/company_role_permissions/${companyRolePermissionUUID}`);
const getCompanyRolePure = (companyRole) => ({
    uuid: companyRole.uuid,
    name: companyRole.name,
    company_uuid: companyRole.company_uuid,
    description: companyRole.description
});
