import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { SettingsLayout } from '../../components/settings_layout';
import { Section } from '../../../../../components/containers/section';
import { TableLite } from '../../../../../components/content_display/table_lite';
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu';
import { Check, Mail, PlusIcon, TrashIcon } from 'lucide-react';
import { Button } from '../../../../../components/buttons/button';
import { db } from '../../../../heat_loss/db';
import { compact } from 'lodash';
import { axiosNodePost, pgRest } from '../../../../../code/axios';
import { EditCompanyPartner } from './edit_company_partner';
import { useLiveQuery } from 'dexie-react-hooks';
const INVITE_STATUS = {
    staged: 'Awaiting accept',
    accepted: 'Accepted'
};
export const OrganisationsManagement = ({ companyUUID }) => {
    const [addModelVisible, setAddModalVisible] = useState(false);
    const companyPartners = useLiveQuery(() => db.company_partners.toArray()) ?? [];
    useEffect(() => {
        const main = async () => {
            const { data: companyPartners } = await pgRest
                .from('company_partners')
                .select('*,partner:teams!company_partners_partner_uuid_fk(name,code),company:teams!company_partners_company_uuid_fk(name,code)')
                .or(`company_uuid.eq.${companyUUID},partner_uuid.eq.${companyUUID}`);
            if (companyPartners) {
                await db.company_partners.clear();
                await db.company_partners.bulkPut(companyPartners);
            }
        };
        main();
    }, []);
    const handleAddCompanyPartner = async (code) => {
        const insertedCompanyPartner = await axiosNodePost(`company_partners/${companyUUID}/partners/${code}`);
        await axiosNodePost(`company_partners/${insertedCompanyPartner.uuid}/send_email`);
        await db.company_partners.add(insertedCompanyPartner);
    };
    const handleRemovePartnership = async (companyPartnerUUID) => {
        await pgRest.from('company_partners').delete().eq('uuid', companyPartnerUUID);
        await db.company_partners.delete(companyPartnerUUID);
    };
    const handleAcceptPartnership = async (companyPartnerUUID) => {
        await pgRest.from('company_partners').update({ status: 'accepted' }).eq('uuid', companyPartnerUUID);
        await db.company_partners.update(companyPartnerUUID, { status: 'accepted' });
    };
    const columns = [
        { name: 'Organisation', render: (row) => _jsx("div", { children: row.company_uuid === companyUUID ? row.partner?.name : row.company?.name }) },
        { name: 'Invited', render: (row) => _jsx("div", { children: new Date(row.created_at).toLocaleDateString() }) },
        { name: 'Status', render: (row) => _jsx("div", { children: INVITE_STATUS[row.status] }) },
        {
            name: '',
            render: (row) => _jsx("div", { className: 'flex justify-end', children: _jsx(DropdownMenu, { items: compact([
                        row.partner_uuid === companyUUID && { label: 'Accept invite', onClick: () => handleAcceptPartnership(row.uuid), icon: Check },
                        row.partner_uuid !== companyUUID && {
                            label: 'Resend invite',
                            onClick: async () => await axiosNodePost(`company_partners/${row.uuid}/send_email`),
                            icon: Mail
                        },
                        {
                            label: 'Remove partnership',
                            onClick: () => handleRemovePartnership(row.uuid),
                            icon: TrashIcon,
                            confirmText: 'Are you sure you want to remove this partner from the company?'
                        }
                    ]) }) })
        }
    ];
    return (_jsxs(_Fragment, { children: [_jsx(SettingsLayout, { heading: 'Organisation partners', description: 'Send a request to a representative to add them to your list of approved partners. Once they accept, you\'ll be able to invite them to your jobs.', actions: _jsx(Button, { colour: 'DARK', onClick: () => setAddModalVisible(true), iconLeft: PlusIcon, children: "Add partner" }), children: _jsx(Section, { title: "Partners", border: true, children: _jsx(TableLite, { columns: columns, rows: companyPartners, alignRight: false }) }) }), addModelVisible && _jsx(EditCompanyPartner, { onSave: handleAddCompanyPartner, setVisible: () => setAddModalVisible(false) })] }));
};
