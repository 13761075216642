import { z } from 'zod';
import { UserPublicProfileSchema } from './user';
import { axiosDeleteV2, axiosGetV2, axiosPatchV2, axiosPostV2 } from '../axios';
import { parseWithZod } from '../zod';
export const UserCompanySchema = z.object({
    user_uuid: z.string().uuid(),
    company_uuid: z.string().uuid(),
    company_role_uuid: z.string().uuid(),
    created_at: z.coerce.date(),
    user: z.lazy(() => UserPublicProfileSchema).nullable().optional(),
    // Only present in job_users
    job_uuid: z.string().uuid().optional()
});
export const CompanyPartners = z.object({
    uuid: z.string().uuid()
});
export const UserCompanyWithCompanyDetailsSchema = UserCompanySchema.extend({
    company: z.object({
        name: z.string()
    })
});
export const getUsersForCompany = async (companyUUID) => {
    const response = await axiosGetV2(`teams/${companyUUID}/users`);
    if (!response)
        return [];
    return parseWithZod(z.array(UserCompanySchema), response);
};
export const addUserToCompany = async ({ companyUUID, email, message, companyRoleUUID }) => {
    const response = await axiosPostV2(`teams/${companyUUID}/users`, {
        email,
        message,
        company_role_uuid: companyRoleUUID
    });
    return parseWithZod(UserCompanySchema, response);
};
export const removeUserFromCompany = async ({ companyUUID, userUUID }) => {
    await axiosDeleteV2(`teams/${companyUUID}/users/${userUUID}`);
};
export const updateUserCompanyRole = async ({ companyUUID, userUUID, companyRoleUUID }) => {
    const response = await axiosPatchV2(`teams/${companyUUID}/users/${userUUID}`, {
        company_role_uuid: companyRoleUUID
    });
    return parseWithZod(UserCompanySchema, response);
};
export const getJobUsersForCompany = async (companyUUID) => {
    const response = await axiosGetV2(`teams/${companyUUID}/job_users`);
    if (!response)
        return [];
    return parseWithZod(z.array(UserCompanySchema), response);
};
