import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Text } from './text';
import { WrappedIcon } from '../buttons/wrapped_icon';
import { ChevronDown, ChevronUp } from 'lucide-react';
export const Accordion = ({ rows }) => {
    const [expandedId, setExpandedId] = useState();
    if (rows.length === 0)
        return;
    const toggleExpanded = (id) => { setExpandedId((prev) => prev === id ? undefined : id); };
    return _jsx("div", { className: "rounded divide-y bg-white text-sm w-full", children: rows.map((x, i) => _jsxs("div", { children: [_jsxs("div", { onClick: () => { toggleExpanded(i); }, className: "py-3 flex flex-grow justify-between items-center cursor-pointer", children: [_jsx(Text, { bold: true, children: x.title }), _jsx(WrappedIcon, { icon: expandedId === i ? ChevronUp : ChevronDown })] }), expandedId === i && _jsxs("div", { className: "py-3 text-gray-500", children: [" ", x.body] })] }, i)) });
};
