import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { SettingsLayout } from '../components/settings_layout';
import { AdminContext } from '../../admin_layout';
import { Section } from '../../../../components/containers/section';
import { TableLite } from '../../../../components/content_display/table_lite';
import { contextSensitiveFormatDate } from '../../../../code/format_date';
import { DropdownMenu } from '../../../../components/buttons/dropdown_menu';
import { PencilIcon, PlusIcon, UserMinus2 } from 'lucide-react';
import { Button } from '../../../../components/buttons/button';
import { Text } from '../../../../components/content_display/text';
import { Input } from '../../../../components/inputs_and_selections/input';
import { Select } from '../../../../components/inputs_and_selections/select';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Modal } from '../../../../components/containers/modal';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { addUserToCompany, removeUserFromCompany, updateUserCompanyRole } from '../../../../code/models/users_companies';
import { chain, omit, orderBy } from 'lodash';
import { getLeadsList, removeUserFromJob, updateUserJobRole } from '../../../../code/models/lead';
const userStatusToText = (status) => {
    switch (status) {
        case 'staged':
            return 'Sent';
        case 'active':
            return 'Accepted';
        case 'deactivated':
            return 'Deactivated';
        default:
            return 'Unknown';
    }
};
export const UserManagement = ({ company }) => {
    const adminContext = useContext(AdminContext);
    const [addUserModalVisible, setAddUserModalVisible] = useState(false);
    const [editRoleUserCompany, setEditRoleUserCompany] = useState(null);
    const [editExternalGuest, setEditExternalGuest] = useState(null);
    const [jobs, setJobs] = useState([]);
    useEffect(() => {
        const fetchJobs = async () => {
            const jobs = await getLeadsList(company.uuid);
            setJobs(jobs ?? []);
        };
        fetchJobs();
    }, [company.uuid]);
    const handleAddUser = async (user) => {
        const newUser = await addUserToCompany({ ...user, companyUUID: company.uuid });
        adminContext.setCompanyUsers([...adminContext.data.companyUsers || [], newUser]);
    };
    const handleRemoveUser = async (userUUID) => {
        await removeUserFromCompany({ companyUUID: company.uuid, userUUID });
        adminContext.setCompanyUsers(adminContext.data.companyUsers?.filter(user => user.user?.uuid !== userUUID) ?? []);
    };
    const handleUpdateUser = async (user) => {
        const updatedUser = await updateUserCompanyRole({ ...user, companyUUID: company.uuid });
        adminContext.setCompanyUsers(adminContext.data.companyUsers?.map(user => user.user?.uuid === updatedUser.user?.uuid ? updatedUser : user) ?? []);
    };
    const handleDeleteUserJob = async (jobUserUuid, jobUuid) => {
        await removeUserFromJob({ companyUuid: company.uuid, jobUuid, jobUserUuid });
        const updatedCompanyJobUsers = adminContext.data.companyJobUsers?.filter(jobUser => jobUser.job_uuid !== jobUuid || jobUser.user_uuid !== jobUserUuid) ?? [];
        adminContext.setCompanyJobUsers(updatedCompanyJobUsers);
        // Also update the modal's state
        if (editExternalGuest) {
            setEditExternalGuest({
                ...editExternalGuest,
                jobs: editExternalGuest.jobs.filter(job => job.uuid !== jobUuid)
            });
        }
    };
    const handleUpdateUserJob = async (jobUserUuid, jobUuid, roleUuid) => {
        const updatedUserJob = await updateUserJobRole({ companyUuid: company.uuid, jobUserUuid, jobUuid, roleUuid });
        if (!updatedUserJob)
            return;
        const updatedCompanyJobUsers = adminContext.data.companyJobUsers?.map(jobUser => jobUser.user?.uuid === updatedUserJob.user_uuid && jobUser.job_uuid === jobUuid
            ? { ...jobUser, company_role_uuid: updatedUserJob.company_role_uuid }
            : jobUser) ?? [];
        adminContext.setCompanyJobUsers(updatedCompanyJobUsers);
        // Also update the modal's state
        if (editExternalGuest) {
            setEditExternalGuest({
                ...editExternalGuest,
                jobs: editExternalGuest.jobs.map(job => job.uuid === jobUuid ? { ...job, company_role_uuid: updatedUserJob.company_role_uuid } : job)
            });
        }
    };
    const teamMemberColumns = [
        { key: 'name', name: 'Name' },
        { key: 'email', name: 'Email' },
        { key: 'added', name: 'Added' },
        { key: 'status', name: 'Status' },
        { key: 'role', name: 'Role' },
        { key: 'actions', name: '' }
    ];
    const sortedCompanyUsers = orderBy(adminContext.data.companyUsers, ['company_role_uuid', 'created_at'], ['asc', 'asc']);
    const sortedCompanyJobUsers = chain(adminContext.data.companyJobUsers)
        .groupBy('user_uuid')
        .map((items) => ({
        ...omit(items[0], 'job_uuid'),
        jobs: items.map(item => ({
            ...jobs.find(job => job.uuid === item.job_uuid),
            added_at: item.created_at,
            company_role_uuid: item.company_role_uuid
        }))
    }))
        .sortBy('user.email')
        .value();
    const teamMemberRows = sortedCompanyUsers.map(userCompany => ({
        name: _jsx(Text, { size: "SM", bold: true, children: (userCompany.user?.first_name || userCompany.user?.last_name) ? `${userCompany.user?.first_name || ''} ${userCompany.user.last_name || ''}` : 'N/A' }),
        email: userCompany.user?.email ?? 'N/A',
        added: contextSensitiveFormatDate(userCompany.created_at),
        status: userStatusToText(userCompany.user?.status),
        role: company?.company_roles.find(role => role.uuid === userCompany.company_role_uuid)?.name ?? 'Unknown',
        actions: _jsx(DropdownMenu, { items: [
                {
                    label: 'Edit role',
                    onClick: () => setEditRoleUserCompany(userCompany),
                    icon: PencilIcon,
                    disabled: userCompany.user?.uuid === adminContext.data.user?.uuid
                },
                {
                    label: 'Remove',
                    onClick: () => handleRemoveUser(userCompany.user_uuid),
                    icon: UserMinus2,
                    disabled: adminContext.data.companyUsers?.length === 1 || userCompany.user?.uuid === adminContext.data.user?.uuid,
                    confirmText: 'Are you sure you want to remove this user from the company?'
                }
            ] })
    })) ?? [];
    const externalGuestColumns = [
        { key: 'name', name: 'Name' },
        { key: 'email', name: 'Email' },
        { key: 'status', name: 'Status' },
        { key: 'access', name: 'Access' }
    ];
    const externalGuestRows = sortedCompanyJobUsers.map(userJobRow => ({
        name: _jsx(Text, { size: "SM", bold: true, children: (userJobRow.user?.first_name || userJobRow.user?.last_name) ? `${userJobRow.user.first_name || ''} ${userJobRow.user.last_name || ''}` : 'N/A' }),
        email: userJobRow.user?.email ?? 'N/A',
        status: userStatusToText(userJobRow.user?.status),
        access: _jsxs("button", { className: "font-bold underline text-bold cursor-pointer", onClick: () => setEditExternalGuest(userJobRow), children: [userJobRow.jobs.length, " ", userJobRow.jobs.length === 1 ? 'job' : 'jobs'] })
    })) ?? [];
    return (_jsxs(_Fragment, { children: [_jsxs(SettingsLayout, { heading: 'People', description: 'Manage your team members and external guests', actions: _jsx(Button, { colour: 'DARK', onClick: () => setAddUserModalVisible(true), iconLeft: PlusIcon, children: "Invite team member" }), children: [_jsx(Section, { title: "Team members", border: true, children: _jsx(TableLite, { columns: teamMemberColumns, rows: teamMemberRows, alignRight: false }) }), _jsx(Section, { title: "External guests", border: true, children: _jsx(TableLite, { columns: externalGuestColumns, rows: externalGuestRows, alignRight: false }) })] }), _jsx(AddUserModal, { roles: company.company_roles, addUser: handleAddUser, visible: addUserModalVisible, setVisible: setAddUserModalVisible }), editRoleUserCompany && _jsx(EditCompanyRoleModal, { roles: company.company_roles, userCompany: editRoleUserCompany, setUserCompany: setEditRoleUserCompany, updateUser: handleUpdateUser, setVisible: () => setEditRoleUserCompany(null) }), editExternalGuest && _jsx(JobAccessModal, { roles: company.company_roles, userJob: editExternalGuest, deleteUserJob: handleDeleteUserJob, updateUserJob: handleUpdateUserJob, setVisible: () => setEditExternalGuest(null) })] }));
};
const AddUserModal = ({ roles, addUser, visible, setVisible }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [companyRoleUUID, setCompanyRoleUUID] = useState(roles[0].uuid);
    return (_jsx(Modal, { visible: visible, setVisible: setVisible, title: "Invite team member", confirmButtonLabel: 'Send invite', onConfirm: () => addUser({ email, message, companyRoleUUID: companyRoleUUID }), children: _jsxs("div", { className: "space-y-4 w-full", children: [_jsx(Input, { label: "Email", value: email, setValue: setEmail, type: "email", placeholder: 'person@company.com' }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(FormLabel, { labelText: 'Role' }), _jsx(Select, { selectedKey: companyRoleUUID, setSelectedKey: setCompanyRoleUUID, options: roles.map(role => ({ key: role.uuid, value: role.name })) })] }), _jsx(TextArea, { label: "Message", value: message, setValue: setMessage, placeholder: 'Add an optional message' })] }) }));
};
const EditRoleModalInner = ({ roleUUID, setRoleUUID, roles }) => {
    return (_jsx("div", { className: "space-y-4 w-full", children: _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(FormLabel, { labelText: 'Role' }), _jsx(Select, { selectedKey: roleUUID, setSelectedKey: setRoleUUID, options: roles.map(role => ({ key: role.uuid, value: role.name })) })] }) }));
};
const EditCompanyRoleModal = ({ userCompany, setUserCompany, roles, updateUser, setVisible }) => {
    return (_jsx(Modal, { visible: !!userCompany, setVisible: setVisible, title: `Edit role for ${userCompany?.user?.email}`, confirmButtonLabel: 'Save', onConfirm: () => userCompany?.user && updateUser({ userUUID: userCompany.user.uuid, companyRoleUUID: userCompany.company_role_uuid }), children: _jsx(EditRoleModalInner, { roleUUID: userCompany?.company_role_uuid || roles[0].uuid, setRoleUUID: (roleUUID) => setUserCompany((prev) => ({ ...prev, company_role_uuid: roleUUID })), roles: roles }) }));
};
const EditJobRoleModal = ({ userJob, userJobJob, setUserJobJob, roles, updateUser, setVisible }) => {
    return (_jsx(Modal, { visible: !!userJobJob, setVisible: setVisible, title: `Edit role for ${userJob?.user?.email}`, confirmButtonLabel: 'Save', onConfirm: () => userJob?.user && updateUser({ userUUID: userJob.user.uuid, companyRoleUUID: userJobJob.company_role_uuid }), children: _jsx(EditRoleModalInner, { roleUUID: userJobJob?.company_role_uuid, setRoleUUID: (roleUUID) => setUserJobJob((prev) => ({ ...prev, company_role_uuid: roleUUID })), roles: roles }) }));
};
const JobAccessModal = ({ userJob, roles, deleteUserJob, updateUserJob, setVisible }) => {
    const [editRoleUserJobJob, setEditRoleUserJobJob] = useState(null);
    const jobAccessTableColumns = [
        { key: 'job', name: 'Job' },
        { key: 'added', name: 'Added' },
        { key: 'role', name: 'Role' },
        { key: 'actions', name: '' }
    ];
    const jobAccessTableRows = userJob?.jobs.map(job => ({
        job: _jsx(Text, { size: "SM", bold: true, children: job.property.address }),
        added: contextSensitiveFormatDate(job.added_at),
        role: roles.find(role => role.uuid === job.company_role_uuid)?.name ?? 'Unknown',
        actions: _jsx(DropdownMenu, { items: [
                {
                    label: 'Edit role',
                    onClick: () => setEditRoleUserJobJob(job),
                    icon: PencilIcon
                },
                {
                    label: 'Remove from job',
                    onClick: () => deleteUserJob(userJob.user_uuid, job.uuid),
                    icon: UserMinus2,
                    confirmText: 'Are you sure you want to remove this guest from this job?'
                }
            ] })
    })) ?? [];
    return (_jsxs(Modal, { visible: !!userJob, setVisible: setVisible, title: `Edit access for ${userJob?.user?.email}`, children: [_jsx(TableLite, { columns: jobAccessTableColumns, rows: jobAccessTableRows, alignRight: false }), editRoleUserJobJob && _jsx(EditJobRoleModal, { userJob: userJob, userJobJob: editRoleUserJobJob, setUserJobJob: setEditRoleUserJobJob, roles: roles, updateUser: (user) => updateUserJob(userJob?.user_uuid ?? '', editRoleUserJobJob?.uuid ?? '', user.companyRoleUUID), setVisible: () => setEditRoleUserJobJob(null) })] }));
};
